import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App goTo={""}/>} />
                <Route path="/nouvellesession/" element={<App goTo={"nouvellesession"} />} />
                <Route path="/listesessions/" element={<App goTo={"listesessions"} />} />
                <Route path="/session/:id" element={<App  goTo={"session"} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;