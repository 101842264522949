import {createContext} from 'react'

export const serverPHP = (window.location.hostname === 'localhost' || window.location.hostname === "192.168.1.6")
? 'http://192.168.1.6:80/'
: 'https://collab.vmirebeau.fr/serveur/';
export const debug_mode = window.location.hostname === 'localhost'
? true
: false;
export const time_waitingroom_default = 2; //30 secondes
export const time_redaction_default = 10; //30 secondes
export const time_evaluation_default = 4; //30 secondes
export const _key = 978645;

export const DialogContext = createContext(null)