import React, { useState, useContext } from 'react';
import NouvelleSession from './NouvelleSession';
import SessionSelector from './SessionSelector';
import Session from '../phases/Session'
import { useNavigate } from 'react-router-dom';


const Main = ({user, setUser, joinSession, setJoinSession, creatingSession, setCreatingSession, isAdmin, setIsAdmin, setTexteInfo, setTitreInfo, setShowInfo }) => {
  const [derniereMaj, setDerniereMaj] = useState(new Date());
  const [motDePasse, setMotDePasse] = useState("");

  const navigate = useNavigate();


  // Fonction pour gérer la création de session
  const handleCreateSession = () => {
    navigate('/nouvellesession/'); // Démarre l'écran de création de session
  };

  return (
    <div className="main">
      {(user.currentsession !== "" && user.currentsession !== null && user.currentsession !== undefined) ? (
        <Session user={user} setUser={setUser}  derniereMaj={derniereMaj} setDerniereMaj={setDerniereMaj} isAdmin={isAdmin} setIsAdmin={setIsAdmin} motDePasse={motDePasse} setMotDePasse={setMotDePasse}
        setTexteInfo={setTexteInfo}
          setTitreInfo={setTitreInfo}
          setShowInfo={setShowInfo}/>
      ) : creatingSession ? (
        <NouvelleSession user={user} setUser={setUser} derniereMaj={derniereMaj} setDerniereMaj={setDerniereMaj} setMotDePasse={setMotDePasse}
        setTexteInfo={setTexteInfo}
        setTitreInfo={setTitreInfo}
        setShowInfo={setShowInfo}/>
      ) : joinSession ? (
        <SessionSelector user={user} setUser={setUser}  derniereMaj={derniereMaj} setDerniereMaj={setDerniereMaj}/>
      ) : (
        <div className="buttons">
          <button onClick={handleCreateSession} className="bigbutton">
            Commencer<br />une session
          </button>
          <button className="bigbutton" onClick={() => navigate('/listesessions/')}>
            Rejoindre<br />une session
          </button>
        </div>
      )}
    </div>
  );
};

export default Main;
