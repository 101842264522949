import React, { useState, useEffect } from 'react';
import { serverPHP } from '../const/consts';
import './Administrateur.css';
import PopupConfirmer from '../composants/PopupConfirmer';

const Administrateur = ({ user, setUser, phase, goNext, closeSession, redaction, setShowInfoAdmin }) => {
    const [contributions, setContributions] = useState([]);
    const [users, setUsers] = useState([]);
    const [stableUsers, setStableUsers] = useState([]); // Copie stable des utilisateurs
    const [bannedUsers, setBannedUsers] = useState([]); // État pour stocker les utilisateurs bannis
    const [selectedContribution, setSelectedContribution] = useState(null);
    const [selectedUser, setSelectedUser] = useState('');
    const [action, setAction] = useState(() => { });
    const [descriptionAction, setDescriptionAction] = useState('');
    const [showInfo, setShowInfo] = useState(false);

    const fetchCurrentContributions = async () => {
        try {
            const response = await fetch(`${serverPHP}/getCurrentContributionsAndUsers.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_id: user.currentsession,
                    phase: phase - (redaction ? 0 : 1),
                }),
            });
            const data = await response.json();

            // Récupérer la liste des utilisateurs actifs et bannis
            const activeUsersList = data.users || [];
            const bannedUsersList = data.banlist || [];

            setContributions(data.contributions || []);
            setUsers(activeUsersList);
            setBannedUsers(bannedUsersList);

            // Conserver la sélection actuelle si l'utilisateur sélectionné existe toujours

            // Mettre à jour stableUsers uniquement si users a changé
            setStableUsers(activeUsersList);
        } catch (error) {
            console.error('Error fetching contributions:', error);
        }
    };

    useEffect(() => {
        fetchCurrentContributions();

        const intervalId = setInterval(fetchCurrentContributions, 5000);

        return () => clearInterval(intervalId);
    }, [user.currentsession, phase]);

    useEffect(() => {
        if (users.every(u => u.id != parseInt(selectedUser)) && bannedUsers.every(u => u.id != parseInt(selectedUser))) {
            // Si l'utilisateur sélectionné actuel n'existe pas dans la nouvelle liste, sélectionner le premier utilisateur
            if (users.length > 0) {
                setSelectedUser(users[0].id);
            } else if (bannedUsers.length > 0) {
                setSelectedUser(bannedUsers[0].id);
            } else {
                setSelectedUser(''); // Réinitialiser si aucune option disponible
            }
        }
    }, [users, bannedUsers])

    const showConfirmationDialog = (action) => {
        setAction(() => () => action());
        setShowInfo(true);
    };

    const handlePhaseSuivante = () => {
        setDescriptionAction("forcer le passage à la phase suivante");
        showConfirmationDialog(() => {
            goNext(null, true);
        });
    };

    const handleFinSession = () => {
        setDescriptionAction("forcer la fermeture de la session");
        showConfirmationDialog(() => {
            closeSession();
        });
    };

    const handleBanUser = () => {
        const isBanned = bannedUsers.some(u => u.id === parseInt(selectedUser));
        setDescriptionAction(isBanned ? "enlever le ban de cet utilisateur" : "bannir cet utilisateur");
        showConfirmationDialog(async () => {
            try {
                const response = await fetch(`${serverPHP}/${isBanned ? 'unbanUser.php' : 'banUser.php'}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        admin_id: user.id,
                        user_id: selectedUser,
                        session_id: user.currentsession,
                    }),
                });
                const data = await response.json();

                if (data.success) {
                    fetchCurrentContributions(); // Rafraîchir la liste des utilisateurs après l'action
                } else {
                    console.error(`Error ${isBanned ? 'unbanning' : 'banning'} user:`, data.message);
                }
            } catch (error) {
                console.error(`Error ${isBanned ? 'unbanning' : 'banning'} user:`, error);
            }
        });
    };

    const handleDeleteContribution = async () => {
        if (!selectedContribution) return;

        try {
            const response = await fetch(`${serverPHP}/deleteContribution.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    admin_id: user.id,
                    contribution_id: selectedContribution,
                    session_id: user.currentsession,
                }),
            });
            const data = await response.json();

            if (data.success) {
                // Rafraîchir les contributions après suppression
                fetchCurrentContributions();
            } else {
                console.error('Error deleting contribution:', data.message);
            }
        } catch (error) {
            console.error('Error deleting contribution:', error);
        }
    };

    const isSelectedUserBanned = bannedUsers.some(u => u.id === parseInt(selectedUser));

    // Fonction pour classer les utilisateurs selon leur rôle
    const groupUsersByRole = (users) => {
        return {
            redacteurs: users.filter(user => parseInt(user.role) === 0),
            evaluateurs: users.filter(user => parseInt(user.role) === 1),
            spectateurs: users.filter(user => parseInt(user.role) === 2)
        };
    };

    const { redacteurs, evaluateurs, spectateurs } = groupUsersByRole(stableUsers);

    return (
        <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button onClick={handlePhaseSuivante}>Phase suivante</button>
                    <div className="separator"></div>
                    <button onClick={handleFinSession}>Fin de session</button>
                </div>

                <div className="bloccontribution">

                    <div style={{ width: '100%' }}>
                        <select
                            size={7}
                            style={{ width: '100%' }}
                            value={selectedContribution || ''}
                            onChange={(e) => setSelectedContribution(e.target.value)}
                        >
                            {contributions.map((contribution) => (
                                <option key={contribution.id} value={contribution.id}>
                                    Id {`${contribution.user_id} : ${contribution.contenu}`}
                                </option>
                            ))}
                        </select>
                        <div style={{ width: '100%', display: redaction ? 'flex' : 'block'}}>
                            {redaction
                            ? <button
                                onClick={handleDeleteContribution}
                                style={{
                                    height: '40px',
                                    width: '300px',
                                    margin: '10px auto auto auto',
                                    padding: 0,
                                    backgroundColor: selectedContribution ? '#ff4d4d' : '#ccc',
                                    cursor: selectedContribution ? 'pointer' : 'not-allowed'
                                }}
                                disabled={!selectedContribution}
                            >
                                Supprimer cette contribution
                            </button>
                            : <div style={{display: 'flex', justifyContent: 'center'}}>Contributions de la phase de rédaction précédente</div>}
                        </div>
                    </div>

                </div>
                <div className="banlist">
                    <select
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                        style={{ flexGrow: 1 }}
                    >
                        <optgroup label="RÉDACTEURS">
                            {redacteurs.map((userOption) => (
                                <option key={userOption.id} value={userOption.id}>
                                    Id {`${userOption.id} - ${userOption.name}`}
                                </option>
                            ))}
                        </optgroup>
                        <optgroup label="ÉVALUATEURS">
                            {evaluateurs.map((userOption) => (
                                <option key={userOption.id} value={userOption.id}>
                                    Id {`${userOption.id} - ${userOption.name}`}
                                </option>
                            ))}
                        </optgroup>
                        <optgroup label="SPECTATEURS">
                            {spectateurs.map((userOption) => (
                                <option key={userOption.id} value={userOption.id}>
                                    Id {`${userOption.id} - ${userOption.name}`}
                                </option>
                            ))}
                        </optgroup>
                        <optgroup label="BANNIS">
                            {bannedUsers.map((userOption) => (
                                <option key={userOption.id} value={userOption.id}>
                                    {`[BANNI] Id ${userOption.id} - ${userOption.name}`}
                                </option>
                            ))}
                        </optgroup>
                    </select>
                    <button
                        onClick={handleBanUser}
                        style={{
                            height: '40px',
                            width: '300px',
                            padding: 0,
                            backgroundColor: isSelectedUserBanned ? '#ff4d4d' : '',
                            color: isSelectedUserBanned ? '#fff' : ''
                        }}
                        className={selectedUser == user.id ? "boutoninactif banbutton" : "banbutton"}
                        disabled={selectedUser == user.id}  // Désactive le bouton si l'utilisateur sélectionné est le même que l'utilisateur actuel
                    >
                        {isSelectedUserBanned ? "Enlever le ban" : "Bannir cet utilisateur"}
                    </button>
                </div>
            {showInfo && (
                <PopupConfirmer titre="Confirmation requise" onClose={() => {setShowInfo(false); setShowInfoAdmin(false) }} action={action}>
                    <>Voulez-vous vraiment {descriptionAction} ?</>
                </PopupConfirmer>
            )}
        </>
    );
}

export default Administrateur;
