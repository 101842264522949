const images = [
  "alligator",
  "auroch",
  "axolotl",
  "blaireau",
  "bouquetin",
  "buffalo",
  "cameleon",
  "canard",
  "castor",
  "chacal",
  "chameau",
  "chauve-souris",
  "chinchilla",
  "chupacabra",
  "citrouille",
  "cormoran",
  "corneille",
  "coyote",
  "dauphin",
  "dingo",
  "dinosaure",
  "dragon",
  "fourmilier",
  "furet",
  "girafe",
  "glouton",
  "gopher",
  "grenouille",
  "grizzly",
  "guépard",
  "hippopotame",
  "hyène",
  "hérisson",
  "ifrit",
  "iguane",
  "koala",
  "kraken",
  "lama",
  "lamantin",
  "lapin",
  "ligre",
  "loris",
  "loup",
  "loutre",
  "lémur",
  "léopard",
  "morse",
  "mouffette",
  "mouton",
  "narval",
  "nyancat",
  "orang-outan",
  "ornithorynque",
  "panda",
  "pingouin",
  "python",
  "quagga",
  "raton_laveur",
  "renard",
  "rhinocéros",
  "singe",
  "souris",
  "suisse",
  "tatou",
  "tortue",
  "vison",
  "wombat",
  "écureuil",
  "éléphant"
];

// Exemple d'utilisation : accéder au premier nom de fichier
export default images