import React, { useState, useRef, useEffect, useCallback } from 'react';
import './AllContainers.css';
import medaille_or from '../assets/medaille_or.svg';
import copy from '../assets/copy.svg';


const romanNumerals = ["I", "II", "III", "IV", "V", "VI"]; // Pour les parties
const alphabet = ['a', 'b', 'c', 'd']; // Pour les sous-parties

const AllContainers = ({ contributions, handleContributionClick, parties, sousparties }) => {
    const [currentOpened, setCurrentOpened] = useState(-1);

    const handleTitleClick = useCallback((index) => {
        if (currentOpened === index) {
          setCurrentOpened(-1);
        } else {
          setCurrentOpened(index);
        }
    }, [currentOpened]);

    const getPrefixForPlan = (index) => {
        const partieIndex = Math.floor(index / (sousparties + 1));
        const souspartieIndex = index % (sousparties + 1);

        if (souspartieIndex === 0) {
            return `${romanNumerals[partieIndex]}`;
        } else {
            return `${alphabet[souspartieIndex - 1]}`;
        }
    };

    return (
        <>
            {contributions.map((phaseContent, index) => {
                const bestContribution = phaseContent.contributions[0];
                const prefix = parties != null && sousparties != null ? getPrefixForPlan(index) : (index + 1);
                const className = parties != null && sousparties != null ? (index % (sousparties + 1) === 0 ? 'grandepartie' : 'indent_plan') : '';

                return (
                    <TitreContainer
                        key={index}
                        handleTitleClick={() => handleTitleClick(index)}
                        opened={index === currentOpened}
                        texte={bestContribution.contribution}
                        prefix={prefix}
                        className={className}
                        handleContributionClick={handleContributionClick}
                        allContributions={phaseContent.contributions}
                    />
                );
            })}
        </>
    );
};

const TitreContainer = React.memo(({ texte, handleTitleClick, opened, prefix, className, handleContributionClick, allContributions }) => {
  const maxTitleLength = 200;

  // Utiliser le texte tronqué si le composant n'est pas ouvert
  const displayedText = opened ? texte : (texte.length > maxTitleLength ? texte.slice(0, maxTitleLength) + "..." : texte);

  const handleMedalClick = (event) => {
    event.stopPropagation(); // Empêche la propagation du clic au parent
    handleContributionClick(allContributions); // Appelle la fonction handleContributionClick
  };

  return (
    <div className={`component ${className}`}>
      <div
        className={`title-allcontainer title-ouvrable`}
        onClick={handleTitleClick}
      >
        <div className={`title ${opened ? 'title-ouvert' : 'title-ferme'}`}>
          {prefix}. {displayedText}
        </div>

          <img 
            src={medaille_or} 
            className="boutonContrib medaille-or" 
            alt="Médaille d'or" 
            onClick={handleMedalClick} // Ajout de l'événement de clic pour la médaille
            style={{opacity:opened ? 0.2 : 0.6}}
          />
        
      </div>
    </div>
  );
});




export default AllContainers;