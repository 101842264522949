import React, { useState } from 'react';
import Header from './Header';
import Main from './Main';
import './Container.css'
import InfoTitre from '../composants/infotitre';



function Container({ user, setUser, creatingSession, setCreatingSession, joinSession, setJoinSession }) {

  const [isAdmin, setIsAdmin] = useState(false);  // État pour vérifier si l'utilisateur est administrateur
  const [showInfo, setShowInfo] = useState(false);
  const [texteInfo, setTexteInfo] = useState(<></>);
  const [titreInfo, setTitreInfo] = useState("");

  return (
    <>
      <div className="central-container">
        <Header
          user={user}
          setUser={setUser}
          creatingSession={creatingSession}
          setCreatingSession={setCreatingSession}
          joinSession={joinSession}
          setJoinSession={setJoinSession}
          isAdmin={isAdmin}
          setIsAdmin={setIsAdmin}
        />
        <Main
          user={user}
          setUser={setUser}
          creatingSession={creatingSession}
          setCreatingSession={setCreatingSession}
          joinSession={joinSession}
          setJoinSession={setJoinSession}
          isAdmin={isAdmin}
          setIsAdmin={setIsAdmin}

          setTexteInfo={setTexteInfo}
          setTitreInfo={setTitreInfo}
          setShowInfo={setShowInfo}
        />
        {showInfo && (
          <InfoTitre titre={titreInfo} onClose={() => setShowInfo(false)}>
            {texteInfo}
          </InfoTitre>
        )}
      </div>
    </>);
}

export default Container;
