export const getTachePlan = (index) => {
    
    const taches = [
        "Partie I", "Partie I",
        "Sous-partie I.a", "Sous-partie I.a",
        "Sous-partie I.b", "Sous-partie I.b",
        "Partie II", "Partie II",
        "Sous-partie II.a", "Sous-partie II.a",
        "Sous-partie II.b", "Sous-partie II.b",
        "Partie III", "Partie III",
        "Sous-partie III.a", "Sous-partie III.a",
        "Sous-partie III.b", "Sous-partie III.b"
    ];

    //console.log("index, taches[index-1]",index,taches[index-1]);
    // On utilise l'index - 1 car les index en JavaScript sont basés sur 0
    return taches[index - 1];
}

export const getMiniTachePlan = (index) => {
    
    const taches = [
        "Rédaction I", "Évaluation I",
        "Rédaction I.a", "Évaluation I.a",
        "Rédaction I.b", "Évaluation I.b",
        "Rédaction II", "Évaluation II",
        "Rédaction II.a", "Évaluation II.a",
        "Rédaction II.b", "Évaluation II.b",
        "Rédaction III", "Évaluation III",
        "Rédaction III.a", "Évaluation III.a",
        "Rédaction III.b", "Évaluation III.b"
    ];

    //console.log("index, taches[index-1]",index,taches[index-1]);
    // On utilise l'index - 1 car les index en JavaScript sont basés sur 0
    return taches[index - 1];
}
