import React, { useState, useEffect } from 'react';

const romanNumerals = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

const PhaseTitle = ({ phase, type, parametres }) => {

    const [titreAffiche, setTitreAffiche] = useState('');

    useEffect(() => {
        if (phase == 0) {
            setTitreAffiche("En attente");
        } else if (type === "plan") {
            const jsonParametres = JSON.parse(parametres);
            const parties = jsonParametres.parties;
            const sousparties = jsonParametres.sousparties;

            const prefix = getPrefixForPhase(phase, parties, sousparties);
            setTitreAffiche(prefix);
        } else if (type === "redaction") {
            setTitreAffiche(`Phase ${Math.floor((phase + 1) / 2)}`);
        }
    }, [parametres, type, phase]);

    const getPrefixForPhase = (phase, parties, sousparties) => {
        const phasesPerPartie = 1 + sousparties;
        const phaseGroupIndex = Math.floor((phase - 1) / 2);
        const partieIndex = Math.floor(phaseGroupIndex / phasesPerPartie);
        const subPartieIndex = phaseGroupIndex % phasesPerPartie;

        if (subPartieIndex === 0) {
            return `Partie ${romanNumerals[partieIndex]}`;
        } else {
            return `Sous-partie ${romanNumerals[partieIndex]}.${alphabet[subPartieIndex - 1]}`;
        }
    };

    return <>{titreAffiche}</>;
};

export default PhaseTitle;
