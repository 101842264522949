import React, { useState, useEffect, useMemo } from 'react';
import './SyntheseSession.css';
import ShowTopContribution from './ShowTopContribution';
import InfoTitre from '../composants/infotitre';
import AllContainers from '../composants/AllContainers';

const romanNumerals = ["I", "II", "III", "IV", "V", "VI"]; // Élargi pour couvrir plus de cas si nécessaire
const alphabet = ['a', 'b', 'c', 'd']; // Pour les sous-parties

const SyntheseSession = ({ sujet, premieres_contributions, contribution_actuelle = null, setTexteInfo, setTitreInfo, setShowInfo, setPrefixNext = false, parametres, type, redactionLongue, redactionLonguePourTop, resultatFinal = false }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [texte, setTexte] = useState(<></>);

  const [parties, setParties] = useState(0);
  const [sousparties, setSousparties] = useState(0);

  useEffect(() => {
    if (type === "plan") {
      const parsedParametres = typeof parametres === 'string' ? JSON.parse(parametres) : parametres;
      setParties(parsedParametres.parties || 0);
      setSousparties(parsedParametres.sousparties || 0);
    }
  }, [parametres, type]);

  const sortedContributions = useMemo(() => {
    return [...premieres_contributions].sort((a, b) => a.phase - b.phase);
  }, [premieres_contributions]);

  const getPrefixForPlan = (index) => {
    const partieIndex = Math.floor(index / (sousparties + 1));
    const souspartieIndex = index % (sousparties + 1);

    if (souspartieIndex === 0) {
      return `${romanNumerals[partieIndex]}.`;
    } else {
      return `${alphabet[souspartieIndex - 1]}.`;
    }
  };

  const handleContributionClick = (contributions) => {
    setTexte(<ShowTopContribution contributions={contributions} redactionLongue={redactionLonguePourTop} />);
    setShowPopup(true);
  };

  const renderContributions = () => {
    if (type === "redaction") {
      return (
        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
          {redactionLongue ? (
            <AllContainers contributions={sortedContributions} handleContributionClick={handleContributionClick} />
          ) : (
            <>
              {(resultatFinal && premieres_contributions.length === 1) ? (
                <div key={0} className="line planHover grandepartie" onClick={() => handleContributionClick(sortedContributions[0].contributions)}>
                  {sortedContributions[0].contributions[0].contribution}
                </div>
              ) : (
                sortedContributions.map((phaseContent, index) => {
                  const bestContribution = phaseContent.contributions[0];
                  return (
                    <div key={index} className="line planHover grandepartie" onClick={() => handleContributionClick(phaseContent.contributions)}>
                      {(index + 1) + ". " + bestContribution.contribution}
                    </div>
                  );
                })
              )}
            </>
          )}
        </div>
      );
    } else if (type === "plan") {
      return (
        <div className="presentationcontributions" style={{  }}>
          {redactionLongue ? (
            <AllContainers contributions={sortedContributions} handleContributionClick={handleContributionClick} parties={parties} sousparties={sousparties}/>
          ) : (
            sortedContributions.map((phaseContent, index) => {
              const bestContribution = phaseContent.contributions[0];
              const prefix = getPrefixForPlan(index);

              return (
                <div key={index} className={`line planHover ${index % (sousparties + 1) === 0 ? 'grandepartie' : 'indent'}`}>
                  <span className="planHover">{prefix} <span onClick={() => handleContributionClick(phaseContent.contributions)}>{bestContribution.contribution}</span></span>
                </div>
              );
            })
          )}
        </div>
      )
    }
  };

  return (
    <div>
      <div>
        <span style={{ textDecoration: 'underline' }}>Sujet :</span> {sujet}
      </div>
      {renderContributions()}
      {setPrefixNext && (
        <><hr className="hrbottom" />
          {type === "plan" ? (
            <div className={`${premieres_contributions.length % (sousparties + 1) === 0 ? 'grandepartie' : 'indent'} current-contribution planHover`}  >
              {getPrefixForPlan(premieres_contributions.length)} {contribution_actuelle ? contribution_actuelle : <></>}
            </div>
          ) : (
            <div className="current-contribution" >
              {premieres_contributions.length + 1}. {contribution_actuelle ? contribution_actuelle : <>(Aucune contribution)</>}
            </div>
          )}
        </>
      )}
      {showPopup && (
        <InfoTitre onClose={() => setShowPopup(false)} titre="Top contributions" big={true}>
          {texte}
        </InfoTitre>
      )}
    </div>
  );
};

export default SyntheseSession;
