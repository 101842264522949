import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { serverPHP, _key, debug_mode } from '../const/consts';
import './ChronoUsers.css';
import Icone_utilisateur from './icone_utilisateur';
import Tooltip from '../Tooltip/tooltip';
import InfoTitre from './infotitre';
import Pseudo_utilisateur from './pseudo_utilisateur';
import down_arrow from '../assets/down_arrow.svg';

// Memoized ColonneUsers component
const ColonneUsers = React.memo(({ titre, users, handleShowUsers }) => {
  // Limiter les utilisateurs affichés à 14, et ajouter un utilisateur fictif spécial si nécessaire
  const maxDisplayUsers = 14;
  const shouldShowMore = users.length > maxDisplayUsers;

  // Crée une liste des utilisateurs à afficher
  const displayedUsers = shouldShowMore ? users.slice(0, maxDisplayUsers) : users;

  // Icône utilisateur spécial pour représenter les utilisateurs restants
  const moreUsersIcon = shouldShowMore && (
    <Icone_utilisateur
      user={{ color: "195da9", image: null }}
      height={40}
      stack={true}
      suspension={true}
    />
  );

  return (
    <div className="colonnetableau" onClick={() => handleShowUsers(titre, users)}>
      <h4 style={{ marginTop: '0px' }}>{titre}</h4>
      <div className="colonneimages">
        {displayedUsers.map((user, index) => (
          <Tooltip text={user.name} key={index}>
            <Icone_utilisateur user={user} height={40} stack={true} />
          </Tooltip>
        ))}
        {moreUsersIcon}
      </div>
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.titre === nextProps.titre && prevProps.users === nextProps.users;
});


const ChronoUsers = ({
  user, setUser, phase, redaction, currentPhase, setCurrentPhase,
  dureeSession, dernieremaj, forcebig = false, isBig, setIsBig,
  goNext, usersInRoom, setUsersInRoom,
  setTexteInfo, setTitreInfo, setShowInfo, sendMsgBeforeReady, setEndSession
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [titre, setTitre] = useState("");
  const [texte, setTexte] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [spectators, setSpectators] = useState([]);
  const [evaluateurs, setEvaluateurs] = useState([]);
  const [spectatorsCount, setSpectatorsCount] = useState(0);
  const [evaluateursCount, setEvaluateursCount] = useState(0);
  const [tickCount, setTickCount] = useState(0);
  const [timerSet, setTimerSet] = useState(false);
  const timerRef = useRef(null);
  const isDisabled = user.role === 2 || (redaction && user.role === 1);
  const [isCheckingAfterClick, setIsCheckingAfterClick] = useState(false)

  const updateUsersInRoom = useCallback((newUsers) => {
    setUsersInRoom((prevUsers) => {
      const isEqual = prevUsers.length === newUsers.length &&
        prevUsers.every((prevUser, index) => {
          const newUser = newUsers[index];
          return prevUser.id === newUser.id &&
                 prevUser.isReady === newUser.isReady &&
                 prevUser.role === newUser.role &&
                 prevUser.name === newUser.name;  // Ajout de la vérification du nom
        });
  
      if (isEqual) {
        return prevUsers;
      }
  
      return newUsers;
    });
  }, [setUsersInRoom]);

  useEffect(() => {
    const updatedSpectators = usersInRoom.filter(user => user.role === 2);
    const updatedEvaluateurs = usersInRoom.filter(user => user.role === 1);

    setSpectators(updatedSpectators);
    setEvaluateurs(updatedEvaluateurs);
    setSpectatorsCount(updatedSpectators.length);
    setEvaluateursCount(updatedEvaluateurs.length);
  }, [usersInRoom]);

  const renderUsers = useCallback((users, page) => {
    const usersPerPage = 10;
    const startIndex = (page - 1) * usersPerPage;
    const paginatedUsers = users.slice(startIndex, startIndex + usersPerPage);
    const components = paginatedUsers.map((user, index) => (
      <React.Fragment key={user.id}>
        <Pseudo_utilisateur user={user} height={30} />
        {index < paginatedUsers.length - 1 ? " " : ""}
      </React.Fragment>
    ));
    const totalPages = Math.ceil(users.length / usersPerPage);
    const paginationButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button key={i} onClick={() => setCurrentPage(i)} className={(i === currentPage) ? "boutonChiffreOn" : "boutonChiffreOff"}>
          {i}
        </button>
      );
    }
    return users.length > 0 ? (
      <div>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '10px' }}>
          {components}
        </div>
        {totalPages > 1 && (
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
            {paginationButtons}
          </div>
        )}
      </div>
    ) : "Aucun utilisateur trouvé";
  }, [currentPage]);

  const handleShowUsers = useCallback((text, users) => {
    setTitre(text);
    setFilteredUsers(users);
    setCurrentPage(1);
    setTexte(renderUsers(users, 1));
    setShowPopup(true);
  }, [renderUsers]);

  const memorizedColonneUsersReady = useMemo(() => {
    const usersReady = usersInRoom.filter(user => (redaction ? user.role === 0 : (user.role === 0 || user.role === 1)) && user.isReady);
    return <ColonneUsers titre={redaction ? 'Rédacteurs prêts' : 'Utilisateurs prêts'} users={usersReady} handleShowUsers={handleShowUsers} />;
  }, [usersInRoom, handleShowUsers, redaction]);

  const memorizedColonneUsersNotReady = useMemo(() => {
    const usersNotReady = usersInRoom.filter(user => (redaction ? user.role === 0 : (user.role === 0 || user.role === 1)) && !user.isReady);
    return <ColonneUsers titre={redaction ? 'Rédacteurs en attente' : 'Utilisateurs en attente'} users={usersNotReady} handleShowUsers={handleShowUsers} />;
  }, [usersInRoom, handleShowUsers, redaction]);

  const RightContainer = useMemo(() => {
    return (
      <div className="right-container" style={{ display: `${(isBig || forcebig) ? 'grid' : 'none'}` }}>
        <div className="user-list">
          {memorizedColonneUsersReady}
          {memorizedColonneUsersNotReady}
        </div>
        <p className="users-ready-count">
          Utilisateurs prêts : {usersInRoom.filter(user => (redaction ? user.role === 0 : (user.role === 0 || user.role === 1)) && user.isReady).length}/{usersInRoom.filter(user => redaction ? user.role === 0 : (user.role === 0 || user.role === 1)).length}
          {(redaction && (evaluateursCount > 0 || spectatorsCount > 0)) && (
            <span>{` (`}
              {evaluateursCount > 0 && (
                <span className="cliquable" onClick={() => handleShowUsers('Évaluateurs', evaluateurs)}>
                  {`${evaluateursCount} évaluateur${evaluateursCount > 1 ? 's' : ''}`}
                </span>
              )}
              {(evaluateursCount > 0 && spectatorsCount > 0) && ', '}
              {spectatorsCount > 0 && (
                <span className="cliquable" onClick={() => handleShowUsers('Spectateurs', spectators)}>
                  {`${spectatorsCount} spectateur${spectatorsCount > 1 ? 's' : ''}`}
                </span>
              )}
              {`)`}
            </span>
          )}

          {(!redaction && spectatorsCount > 0) && (
            <span>{` (`}
              {spectatorsCount > 0 && (
                <span className="cliquable" onClick={() => handleShowUsers('Spectateurs', spectators)}>
                  {`${spectatorsCount} spectateur${spectatorsCount > 1 ? 's' : ''}`}
                </span>
              )}
              {`)`}
            </span>
          )}
        </p>
      </div>
    );
  }, [usersInRoom, redaction, evaluateursCount, spectatorsCount, evaluateurs, spectators, isBig, forcebig, memorizedColonneUsersReady, memorizedColonneUsersNotReady]);

  useEffect(() => {
    if (showPopup) {
      setTexte(renderUsers(filteredUsers, currentPage));
    }
  }, [currentPage, filteredUsers, showPopup, renderUsers]);

  const fetchServerUpdate = useCallback(() => {
    fetch(`${serverPHP}getServerUpdate.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session_id: user.currentsession,
        user_id: user.id
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const currentTime = new Date(data.currentDateTime);
        const dernieremaj = new Date(data.dernieremaj);
        const elapsedTime = Math.floor((currentTime.getTime() - dernieremaj.getTime()) / 1000);
  
        if (dureeSession === 0) {
          setTimeRemaining(0); // Keep timer at 0 if dureeSession is 0
        } else {
          setTimeRemaining(Math.max(dureeSession - elapsedTime, 0));
        }
  
        setTimerSet(true);
  
        if (data.users) {
          updateUsersInRoom(data.users);
        }
  
        if (data.phase !== undefined && data.phase !== currentPhase) {
          setCurrentPhase(data.phase);
        }
  
        if (data.closed === true) {  // Check if the session is closed
          if (debug_mode) console.log("On a fait un serverUpdate, et on identifie un closed")
          setEndSession(true);        // End the session if closed is true
        }
      })
      .catch((error) => {
        console.error('Failed to fetch data:', error);
      });
  }, [user.currentsession, currentPhase, dureeSession, updateUsersInRoom, setCurrentPhase, setEndSession]);
  

  useEffect(() => {
    setTimeRemaining(null);
    fetchServerUpdate();
  }, [phase, fetchServerUpdate]);

  useEffect(() => {
    if (timeRemaining !== null) {
      timerRef.current = setInterval(() => {
        if (timeRemaining > 0) {
          setTimeRemaining(prevTime => Math.max(prevTime - 1, 0));
        } else if (timeRemaining === 0 && timerSet  && dureeSession !== 0) {
          clearInterval(timerRef.current);
          if (debug_mode) console.log("GoNext à cause du timer");
          goNext();
        }
        if (tickCount % 5 === 0) {
          fetchServerUpdate();
        }
        setTickCount(prev => prev + 1);
      }, 1000);
      return () => clearInterval(timerRef.current);
    }
  }, [timeRemaining, tickCount, fetchServerUpdate, goNext]);

  useEffect(() => {
    if (usersInRoom.length > 0 && !isCheckingAfterClick) {
      const usersToCheck = redaction ? usersInRoom.filter(user => user.role === 0) : usersInRoom.filter(user => user.role === 0 || user.role === 1);
      if (usersToCheck.every(user => user.isReady) && dernieremaj) {
        if (debug_mode) console.log("goNext parce que tout le monde est prêt");
        goNext();
      }
    }
  }, [usersInRoom, redaction, goNext, dernieremaj]);

  const handleReady = () => {
    if (debug_mode) console.log("On se met en ready");
    setIsCheckingAfterClick(true);
  }

  useEffect(() => {
    if (isCheckingAfterClick) {
      if (debug_mode) console.log("on change le isready")
      setUser(prevUser => ({ ...prevUser, isready: true }));
      setUsersInRoom(prevUsers => {
        //console.log("avant le map : prevUsers", prevUsers)
        const targetId = user.id ^ _key;
        const updatedUsers = prevUsers.map(u => (u.id === targetId ? { ...u, isReady: true } : u));
        //console.log("après le map : updatedUsers", updatedUsers)
        if (updatedUsers.length > 0) {
          const usersToCheck = redaction ? updatedUsers.filter(user => user.role === 0) : updatedUsers.filter(user => user.role === 0 || user.role === 1);
          if (usersToCheck.every(_user => _user.isReady) && dernieremaj) {
            if (debug_mode) console.log("goNext parce qu'on clique sur ready")
            setTimeout(() => {goNext(parseInt(user.id, 10));setIsCheckingAfterClick(false);}, 0);
          } else {
            setIsCheckingAfterClick(false);
          }
        }
  
        return updatedUsers;
      });
  
      if (sendMsgBeforeReady !== "" && sendMsgBeforeReady !== undefined) {
        setTexteInfo(sendMsgBeforeReady);
        setTitreInfo("Avertissement");
        setShowInfo(true);
      }
      
    }
    }, [isCheckingAfterClick]);


  const handleCancelReady = useCallback(() => {
    setUser(prevUser => ({ ...prevUser, isready: false }));
    setUsersInRoom(prevUsers => {
      return prevUsers.map(u => u.id === parseInt(user.id, 10) ^ _key ? { ...u, isReady: false } : u);
    });
  }, [setUser, setUsersInRoom, user.id]);

  const handleClick = useCallback(() => {
    if (user.isready) {
      handleCancelReady();
    } else if (!isDisabled) {
      handleReady();
    }
  }, [user.isready, handleCancelReady, handleReady, isDisabled]);

  const readyButton = () => {
    const buttonClass = user.isready
      ? "button boutonready ready "
      : isDisabled
        ? "button boutonready waiting"
        : "button boutonready boutontextready not-ready" + (sendMsgBeforeReady == "" ? " next-hint" : "");
    return (
      <button
        onClick={handleClick}
        className={buttonClass}
        disabled={isDisabled}
      >
        {user.isready ? "Annuler" : ""}
      </button>
    );
  };

  useEffect(() => {
    setUsersInRoom(prevUsers => prevUsers.map(user => ({ ...user, isReady: false })));
  }, [currentPhase, setUsersInRoom]);

  useEffect(() => {
    setUsersInRoom(prevUsers => {
      const updatedUsers = prevUsers.map(u => (u.id ^ _key === parseInt(user.id, 10) ? { ...u, role: user.role } : u));
      return updatedUsers;
    });
  }, [user.role, setUsersInRoom, user.id]);

  // Cleanup function to clear the interval when the component unmounts or when the page is unloaded
  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const formatTime = (seconds) => {
    if (dureeSession === 0) {
      return "∞";
    }
    
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className={`main-container ${(isBig || forcebig) ? 'big' : 'small'}`}>
      <div className="top-bar">

        <>
          <div className="minimal-buttons">
            <div className="progress-container minimal">
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width: `${dureeSession === 0 ? 0 : (timeRemaining / dureeSession) * 100}%` }}
                ></div>
              </div>
              <span className="time-remaining minimal">{timeRemaining === null ? '--:--' : formatTime(timeRemaining)}</span>
            </div>
            <div style={{ display: 'flex' }}>


{!forcebig &&
              < button onClick={() => setIsBig(prev => (!prev))} className="boutonlisteprets" >
                <div className="textelisteprets">{usersInRoom.filter(user => (redaction ? user.role === 0 : (user.role === 0 || user.role === 1)) && user.isReady).length}/{usersInRoom.filter(user => redaction ? user.role === 0 : (user.role === 0 || user.role === 1)).length}</div>
                <div className={`divimage boutonmobile ${!isBig ? 'uparrow_style' : 'downarrow_style'}`} style={{ backgroundColor: 'transparent', marginLeft: '0px' }}>
                  <img src={down_arrow} className='imagestyle' />
                </div>
              </button>
}

              
              {readyButton()}
            </div>
          </div>
        </>
      </div>
      {showPopup && (
        <InfoTitre onClose={() => setShowPopup(false)} titre={titre} big={true}>{texte}</InfoTitre>
      )}
      {RightContainer}
    </div>

  );
};

export default ChronoUsers;
