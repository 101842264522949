import React, { useState, useEffect, useRef } from 'react';
import { serverPHP } from '../const/consts';
import SyntheseSession from './SyntheseSession';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import './ResultatFinal.css';

import docx from '../assets/docx.svg';
import copy from '../assets/copy.svg';

const ResultatFinal = ({ user, sujet, currentPhase, setTexteInfo, setTitreInfo, setShowInfo, parametres, type, redactionLongue }) => {
  const [pastContributions, setPastContributions] = useState([]);
  const planRef = useRef(null);

  useEffect(() => {
    fetch(`${serverPHP}/getContributions.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        session_id: user.currentsession,
        user_id: user.id,
        phase: currentPhase,
        action: 'final',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.past_contributions) {
          setPastContributions(data.past_contributions);
        }
      })
      .catch((error) => console.error('Failed to fetch contributions:', error));
  }, [currentPhase, user.currentsession, user.id]);

  const exportToDocx = () => {
    if (planRef.current) {
      const paragraphs = [];

      paragraphs.push(
        new Paragraph({
          children: [
            new TextRun({
              text: `Sujet : ${sujet}`,
              bold: true,
            }),
          ],
          spacing: {
            after: 200,
          },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: '',
            }),
          ],
          spacing: {
            after: 200,
          },
        })
      );

      const lines = planRef.current.querySelectorAll('.line');
      lines.forEach((line) => {
        const text = line.innerText;
        const indentLevel = line.classList.contains('indent') ? 1 : 0;

        paragraphs.push(
          new Paragraph({
            children: [
              new TextRun({
                text: text,
              }),
            ],
            indent: {
              left: indentLevel * 720,
            },
          })
        );
      });

      const doc = new Document({
        sections: [
          {
            children: paragraphs,
          },
        ],
      });

      Packer.toBlob(doc)
        .then((blob) => {
          saveAs(blob, 'plan.docx');
        })
        .catch((error) => {
          console.error('Failed to generate DOCX file:', error);
        });
    }
  };

  const copyToClipboard = () => {
    if (planRef.current) {
      let textToCopy = `Sujet : ${sujet}\n\n`;

      const lines = planRef.current.querySelectorAll('.line');
      lines.forEach((line) => {
        const indentLevel = line.classList.contains('indent') ? 1 : 0;
        const text = line.innerText;

        textToCopy += `${'\t'.repeat(indentLevel)}${text}\n`;
      });

      navigator.clipboard.writeText(textToCopy).then(() => {
      }).catch(err => {
        console.error('Failed to copy text:', err);
      });
    }
  };

  return (
    <>
      <div className="title-container">
        <span style={{ fontWeight: 'bold' }} className="titre_mobile">Résultat final</span>
        <div className="button-container">
          <img src={docx} onClick={exportToDocx} className="imgexport" />
          <img src={copy} onClick={copyToClipboard} className="imgexport" />
        </div>
      </div>
      <div style={{marginTop:'20px', fontStyle:'italic'}}>Les sessions terminées sont supprimées après 48 heures.</div>
      <div className="cadre" ref={planRef}>
        <SyntheseSession
          sujet={sujet}
          premieres_contributions={pastContributions}
          setTexteInfo={setTexteInfo}
          setTitreInfo={setTitreInfo}
          setShowInfo={setShowInfo}
          parametres={parametres}
          type={type}
          redactionLongue={false}
          redactionLonguePourTop={redactionLongue}
          resultatFinal={true}
        />
      </div>
    </>
  );
};

export default ResultatFinal;
