import React, { useState, useEffect, useRef } from 'react';
import { serverPHP } from '../const/consts';
import './Redaction.css';
import SyntheseSession from './SyntheseSession';
import img_send from '../assets/send.svg';

const MAX_CHAR_LIMIT = 150;

const Redaction = ({
  user,
  setUser,
  phase,
  sujet,
  setIsContributionReady,
  setTexteInfo,
  setTitreInfo,
  setShowInfo,
  setSendMsgBeforeReady,
  parametres,
  type,
  redactionLongue
}) => {
  const [displayedContribution, setDisplayedContribution] = useState('');
  const [contribution, setContribution] = useState('');
  const [charCount, setCharCount] = useState(MAX_CHAR_LIMIT);
  const [pastContributions, setPastContributions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    setDisplayedContribution('');

    fetch(`${serverPHP}/getContributions.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        session_id: user.currentsession,
        user_id: user.id,
        phase: phase,
        action: 'redaction'
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.current_contribution) {
          setDisplayedContribution(data.current_contribution.contribution);
        }
        if (data.past_contributions) {
          setPastContributions(data.past_contributions);
        }
      })
      .catch(error => console.error('Failed to fetch contributions:', error));
  }, [phase, user.currentsession, user.id]);

  useEffect(() => {
    if (displayedContribution !== '') {
      setSendMsgBeforeReady('');
    } else {
      setSendMsgBeforeReady('Veuillez envoyer au moins une contribution avant de passer à la phase suivante. Si vous ne voulez pas participer, vous pouvez adopter le rôle de spectateur en cliquant sur votre pseudonyme en haut à gauche.');
    }
  }, [displayedContribution, setSendMsgBeforeReady]);

  const handleSendContribution = () => {
      fetch(`${serverPHP}/addContribution.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          session_id: user.currentsession,
          user_id: user.id,
          contribution: contribution,
          phase: phase
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setDisplayedContribution(contribution);
            setContribution(''); // Clear the input after sending
            setCharCount(MAX_CHAR_LIMIT); // Reset character count
            inputRef.current.focus(); // Set focus back to the input
          } else {
            console.error('Failed to send contribution:', data.error);
          }
        })
        .catch(error => {
          console.error('Error sending contribution:', error);
        });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !redactionLongue) {
      handleSendContribution();
    }
  };

  const handleChange = (e) => {
    const input = e.target.value;
    setContribution(input);
    if (!redactionLongue && input.length <= MAX_CHAR_LIMIT) {
      setCharCount(MAX_CHAR_LIMIT - input.length);
    }
  };

  const getMessageInfo = (article, statut) => {
    return (
      <div className="message">
        Vous avez le statut {article}
        <span className="engras">{statut}</span>.<br />
        Vous ne pouvez donc pas participer aux phases de rédaction.<br /><br />
        Pour changer votre statut, cliquez sur votre nom tout en haut.
      </div>
    );
  };

  return (
    <>
      {user.role === 0 ? (
        <>
          <div className="allwidth">
            <div className="inputetbouton" style={{ display: redactionLongue ? 'block' : 'flex' }}>
              {redactionLongue ? (
                <textarea
                  ref={inputRef}
                  value={contribution}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Écrivez votre contribution ici..."
                  className={`contribution textarea-contribution ${(!displayedContribution && contribution == "") ? 'next-hint' : ''}`}
                  rows="10"
                />
              ) : (
                <div style={{ position: 'relative', width: '100%' }}>
                  <input
                    ref={inputRef}
                    type="text"
                    value={contribution}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Écrivez votre contribution ici..."
                    className={`contribution ${(!displayedContribution && contribution == "") ? 'next-hint' : ''}`}
                  />
                  {(!redactionLongue && charCount <= 30) && (
                    <div className="char-counter" style={{ position: 'absolute', bottom: '-1rem', right: '0px', color: 'red', fontSize:'09.rem' }}>
                      {charCount} caractères restants
                    </div>
                  )}
                </div>
              )}
              {!redactionLongue && (
                <div
                  onClick={handleSendContribution}
                  className={`divimage clickable boutonmobile showmobile ${(!displayedContribution && contribution !== "") ? 'next-hint' : ''}`}
                >
                  <img src={img_send} className="imagestyle" alt="Send" />
                </div>
              )}
              <button
                onClick={handleSendContribution}
                className={`boutondroite ${!redactionLongue ? 'showwide' : 'boutonEnvoyerRedactionLongue'} ${(!displayedContribution && contribution !== "") ? 'next-hint' : ''}`}
              
              >
                Envoyer
              </button>
            </div>
          </div>
        </>
      ) : user.role === 1 ? (
        getMessageInfo("d'", 'évaluateur')
      ) : user.role === 2 ? (
        getMessageInfo('de ', 'spectateur')
      ) : null}
      <div className="cadre">
        <h4 style={{ marginTop: '0px', textAlign: 'center', marginBottom: '20px' }}>
          {type === 'plan' ? 'Aperçu du plan final' : 'Synthèse de la session'}
        </h4>
        <SyntheseSession
          sujet={sujet}
          premieres_contributions={pastContributions}
          contribution_actuelle={displayedContribution}
          setTexteInfo={setTexteInfo}
          setTitreInfo={setTitreInfo}
          setShowInfo={setShowInfo}
          setPrefixNext={true}
          parametres={parametres}
          type={type}
          redactionLongue={redactionLongue}
          redactionLonguePourTop={redactionLongue}
        />
      </div>
    </>
  );
};

export default Redaction;
