import React from 'react';
import './WaitingRoom.css'; // Import CSS for styling
import RoleSelector from '../composants/roleselector';


const WaitingRoom = ({ user, setUser, isAdmin }) => {


  return (<>
  
<h3 className="margebas titre_mobile">Salle d'attente</h3>
<div className="cadreInfo">

Sélectionnez votre <span className="stress">rôle</span> dans cette session. Vous pourrez le changer à tout moment en cliquant sur votre pseudonyme en haut à gauche.
<div className="role">
  <div className="roleselec">
<RoleSelector user={user} setUser={setUser} isAdmin={isAdmin}/>
</div>
<div style={{fontStyle:"italic"}}>
  {user.role == 0 ?
<>Le rôle de <span className="stress">rédacteur</span> consiste à écrire des contributions. Vous participerez également à l'évaluation de toutes les contributions.</>
: user.role == 1 ?
<>Le rôle d'<span className="stress">évaluateur</span> consiste à donne votre avis sur les contributions produites par les rédacteurs.</>
: user.role == 2 ?
<>Le rôle de <span className="stress">spectateur</span> vous permet de suivre ce qui se passe dans la session, sans jamais intervenir.</>
: <></>}

</div>
</div>
</div>
</>
  );
};

export default WaitingRoom;
