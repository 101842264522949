import Popup from "./popup";

function PopupConfirmer({ children, titre, onClose = () => {}, action, big = false }) {
    return (
      <>
        <Popup onClose={onClose} big={big}>
          <div style={{ textAlign: 'center' }}>
            {big ?
            <h3 style={{ fontWeight: 'bold',marginTop:'0px',marginBottom:'30px' }}>{titre}</h3>

            :
            <h1 style={{ fontWeight: 'bold',marginTop:'0px',marginBottom:'30px' }}>{titre}</h1>
}
<div style={{textAlign:'center'}}>
                {children}
                </div>
                <div style={{ marginTop: '20px', display: 'flex', gap: '10px', justifyContent: 'center' }}>
                    <button onClick={() => { action(); onClose() }}>Confirmer</button>
                    <button onClick={() =>  onClose()}>Annuler</button>
                </div>

          </div>
        </Popup>
      </>
    );
  }
  

export default PopupConfirmer;