import React from 'react';
import './roleselector.css';

const RoleSelector = ({ user, setUser, isAdmin, onValueChange = () => {}, fullWidth = false}) => {
  // Gère le changement de sélection dans la dropdown
  const handleRoleChange = (event) => {
    // Appelle la fonction de gestion du changement de rôle passée en prop
    setUser(prevUser => ({ ...prevUser, role: parseInt(event.target.value, 10) }));
    onValueChange();
  };


  return (
    <select value={user.role} onChange={handleRoleChange} className={`boutonpret mini70px ${fullWidth ? 'fullwidth' : ''}`}>
      <option value="0">Rédacteur</option>
      <option value="1">Évaluateur</option>
      <option value="2">Spectateur</option>
    </select>
  );
};

export default RoleSelector;
