import React from 'react';
import Icone_utilisateur from './icone_utilisateur';
import './pseudo_utilisateur.css'

function Pseudo_utilisateur({ user, height, showSession = false }) {

    const containerStyle = {
        display: 'flex',
        alignItems: 'center'
    };

    const textStyle = {
        marginLeft: '10px',
        textAlign: 'left'
    };

    return (
        <div style={containerStyle}>
            <Icone_utilisateur user={user} height={height} />
            <div style={textStyle}>
                {user.name}<br/>
                {showSession && 
                <span className="rolestyle">
                    {user.currentsession ?
                        <>
                            {(user.role === 0) && <>Rédacteur </>}
                            {(user.role === 1) && <>Évaluateur </>}
                            {(user.role === 2) && <>Spectateur </>}
                            {`dans la session ${user.currentsession}`}
                        </>
                    : 
                        <>Pas de session</>
                    }
                </span>}
            </div>
        </div>
    );
}

export default Pseudo_utilisateur;
