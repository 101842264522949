import React, { useState, useEffect, useCallback } from 'react';
import './Evaluation.css';
import { serverPHP } from '../const/consts';
import flecheG from '../assets/fleche_g.svg';
import flecheGR from '../assets/fleche_g_r.svg';
import flecheD from '../assets/fleche_d.svg';
import flecheDR from '../assets/fleche_d_r.svg';

import visage_m2 from '../assets/visage_m2.svg';
import visage_m1 from '../assets/visage_m1.svg';
import visage_n from '../assets/visage_n.svg';
import visage_p1 from '../assets/visage_p1.svg';
import visage_p2 from '../assets/visage_p2.svg';

import visage_m2g from '../assets/visage_m2g.svg';
import visage_m1g from '../assets/visage_m1g.svg';
import visage_ng from '../assets/visage_ng.svg';
import visage_p1g from '../assets/visage_p1g.svg';
import visage_p2g from '../assets/visage_p2g.svg';

import Tooltip from '../Tooltip/tooltip';
import SyntheseSession from './SyntheseSession';

const Evaluation = ({ user, phase, sujet, setShowInfo, setTitreInfo, setTexteInfo, setEndSession, goNext, closeSession, sendMsgBeforeReady, setSendMsgBeforeReady, parametres, type, redactionLongue }) => {
  const [currentContributions, setCurrentContributions] = useState([]);
  const [pastContributions, setPastContributions] = useState([]);
  const [currentContributionIndex, setCurrentContributionIndex] = useState(0);
  const [userEvaluations, setUserEvaluations] = useState(new Map());
  const [isUpdating, setIsUpdating] = useState(false);

  // Utilitaire pour mélanger un tableau
  const shuffleArray = (array) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  useEffect(() => {
    setIsUpdating(true);
    fetch(`${serverPHP}/getContributions.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        session_id: user.currentsession,
        phase: phase,
        user_id: user.id,
        action: "evaluation"
      })
    })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          console.error("Error in fetching contributions:", data);
          return;
        }

        // Si les contributions n'ont pas encore été mélangées, on les mélange une seule fois
        if (currentContributions.length === 0) {
          const shuffledContributions = shuffleArray(data.current_contributions);
          setCurrentContributions(shuffledContributions);
        }

        setPastContributions(data.past_contributions);

        if (data.current_contributions.length === 1) {
          goNext();
        }

        const evaluationsMap = new Map();
        data.current_contributions.forEach(contribution => {
          let valeurInterne = null;
          if (contribution.valeur !== null) {
            if (contribution.valeur === 0) {
              valeurInterne = 5; // 5 pour représenter "neutral" en interne
            } else {
              valeurInterne = contribution.valeur;
            }
          }
          evaluationsMap.set(contribution.id, valeurInterne);
        });
        setUserEvaluations(evaluationsMap);

      })
      .catch(error => {
        console.error('Failed to fetch contributions:', error);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }, [user.currentsession, phase, goNext, closeSession]);

  const handleEvaluation = (id, newValeur) => {
    if (isUpdating) return;
  
    const currentValeur = userEvaluations.get(id) || null;
  
    let valeur;
    if (newValeur === 5) {
      valeur = currentValeur === 5 ? null : 5; // "neutral"
    } else {
      valeur = currentValeur === newValeur ? null : newValeur; // -2, -1, 1, 2
    }
  
    const valeurEnvoyee = valeur === 5 ? 0 : valeur;
  
    // Create a new Map to trigger a re-render
    const newEvaluations = new Map(userEvaluations);
    newEvaluations.set(id, valeur);
  
    setIsUpdating(true);
    setUserEvaluations(newEvaluations);

    fetch(`${serverPHP}/setEvaluation.php`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id,
            user_id: user.id,
            valeur: valeurEnvoyee
        })
    })
        .then(response => response.json())
        .then(data => {
            if (!data.success) {
                console.error('Failed to set evaluation:', data.error);
            }
        })
        .catch(error => {
            console.error('Error setting evaluation:', error);
            alert('Une erreur est survenue lors de la mise à jour. Veuillez réessayer.');
        })
        .finally(() => {
            setIsUpdating(false);
        });
  };

  useEffect(() => {
    if (!isUpdating) checkAllEvaluated();
  }, [isUpdating])

  const isActive = (buttonValeur) => {
    const currentValeur = userEvaluations.get(currentContributions[currentContributionIndex]?.id);
    return currentValeur === buttonValeur;
  };

  const navigateContributions = (direction) => {
    setCurrentContributionIndex(prev => {
      const newIndex = prev + direction;
      return newIndex < 0 ? 0 : newIndex >= currentContributions.length ? currentContributions.length - 1 : newIndex;
    });
  };

  const checkAllEvaluated = useCallback(() => {
    const evaluatedCount = Array.from(userEvaluations.values()).filter(value => value !== null).length;
    const totalContributions = currentContributions.length;
    
    if (evaluatedCount === totalContributions) {
        setSendMsgBeforeReady("");
    } else {
        setSendMsgBeforeReady("Veuillez évaluer toutes les contributions avant de passer à la phase suivante.");
    }
  }, [userEvaluations, currentContributions, sendMsgBeforeReady]);

  const getMessageInfo = (article, statut) => {
    return (
      <div className="message">
        Vous avez le statut {article}<span className="engras">{statut}</span>.<br />Vous ne pouvez donc pas participer aux phases d'évaluation.<br /><br />Pour changer votre statut, cliquez sur votre nom tout en haut.
      </div>
    );
  };

  const evaluatedCount = Array.from(userEvaluations.values()).filter(value => value !== null).length;
  const totalContributions = currentContributions.length;
  const currentContributionEvaluated = userEvaluations.get(currentContributions[currentContributionIndex]?.id) !== null;

  return (
    <>
      {(user.role === 0) || (user.role === 1) ? (
        
        <div style={{ width: '100%' }}>
          <div className="div_contribution">
            <div className="cadre" style={{ padding: '20px' }}>
              <div className="titreContrib">
                <div style={{ width: '35px' }}>
                  <div className={`imgFlecheMenu imgFlecheImageG ${currentContributionIndex > 0 ? 'flex' : 'hidden'}`} onClick={() => navigateContributions(-1)}>
                    <Tooltip text="Message précédent" direction='down'>
                      <img src={(currentContributionEvaluated && evaluatedCount < totalContributions) ? flecheGR : flecheG} className="imgFlecheImage" alt="Previous" />
                    </Tooltip>
                  </div>
                </div>

                <h4 style={{ marginTop: '0px', textAlign: 'center', marginBottom: '30px', width: '250px' }}>Contribution {currentContributionIndex + 1}/{currentContributions.length}</h4>

                <div style={{ width: '35px' }}>
                  <div className={`imgFlecheMenu imgFlecheImageD ${currentContributionIndex < currentContributions.length - 1 ? 'flex' : 'hidden'}`} onClick={() => navigateContributions(1)}>
                    <Tooltip text="Message suivant" direction='down'>
                      <img src={(currentContributionEvaluated && evaluatedCount < totalContributions) ? flecheDR : flecheD} className="imgFlecheImage" alt="Next" />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <SyntheseSession
                sujet={sujet}
                premieres_contributions={pastContributions}
                contribution_actuelle={currentContributions[currentContributionIndex]?.contribution}
                setTexteInfo={setTexteInfo}
                setTitreInfo={setTitreInfo}
                setShowInfo={setShowInfo}
                setPrefixNext={true}
                parametres={parametres}
                type={type}
                redactionLongue={redactionLongue}
                redactionLonguePourTop={redactionLongue}
              />
              <div className={currentContributionEvaluated ? 'groupeThumbs' : 'groupeThumbs next-hint'}>
                <div className="groupeimages">
                  <div className="evaluation-image-container" onClick={() => handleEvaluation(currentContributions[currentContributionIndex]?.id, -2)}>
                    <img src={visage_m2} className={`evaluation-image normal ${isActive(-2) ? 'active selected' : ''}`} alt="-2" />
                    <img src={visage_m2g} className={`evaluation-image gray ${!isActive(-2) ? 'active' : 'selected'}`} alt="-2 gray" />
                  </div>
                  <div className="evaluation-image-container" onClick={() => handleEvaluation(currentContributions[currentContributionIndex]?.id, -1)}>
                    <img src={visage_m1} className={`evaluation-image normal ${isActive(-1) ? 'active selected' : ''}`} alt="-1" />
                    <img src={visage_m1g} className={`evaluation-image gray ${!isActive(-1) ? 'active' : 'selected'}`} alt="-1 gray" />
                  </div>
                  <div className="evaluation-image-container" onClick={() => handleEvaluation(currentContributions[currentContributionIndex]?.id, 5)}>
                    <img src={visage_n} className={`evaluation-image normal ${isActive(5) ? 'active selected' : ''}`} alt="neutral" />
                    <img src={visage_ng} className={`evaluation-image gray ${!isActive(5) ? 'active' : 'selected'}`} alt="neutral gray" />
                  </div>
                  <div className="evaluation-image-container" onClick={() => handleEvaluation(currentContributions[currentContributionIndex]?.id, 1)}>
                    <img src={visage_p1} className={`evaluation-image normal ${isActive(1) ? 'active selected' : ''}`} alt="+1" />
                    <img src={visage_p1g} className={`evaluation-image gray ${!isActive(1) ? 'active' : 'selected'}`} alt="+1 gray" />
                  </div>
                  <div className="evaluation-image-container" onClick={() => handleEvaluation(currentContributions[currentContributionIndex]?.id, 2)}>
                    <img src={visage_p2} className={`evaluation-image normal ${isActive(2) ? 'active selected' : ''}`} alt="+2" />
                    <img src={visage_p2g} className={`evaluation-image gray ${!isActive(2) ? 'active' : 'selected'}`} alt="+2 gray" />
                  </div>
                </div>
                <span className="recap_evaluations">Évaluations : <span style={{color:evaluatedCount == totalContributions ? 'green' : 'red'}}>{evaluatedCount}/{totalContributions}</span></span>
              </div>
            </div>
          </div>
        </div>
      ) : user.role === 2 ? (
        getMessageInfo("de ", "spectateur")
      ) : null}
    </>
  );
}

export default Evaluation;
