import React, { useState, useEffect, useCallback } from 'react';
import WaitingRoom from './WaitingRoom';
import Redaction from './Redaction';
import ChronoUsers from '../composants/ChronoUsers';
import { serverPHP, debug_mode, time_waitingroom_default, time_redaction_default, time_evaluation_default } from '../const/consts';
import './Session.css';
import Evaluation from './Evaluation';
import ResultatFinal from './ResultatFinal';
import AfficheTitre from '../composants/AfficheTitre';
import { getTachePlan } from './FonctionsPlan';
import { useNavigate } from 'react-router-dom';

const Session = ({ user, setUser, derniereMaj, setDerniereMaj, isAdmin, setIsAdmin, setTexteInfo, setTitreInfo, setShowInfo, motDePasse, setMotDePasse }) => {
    const [usersInRoom, setUsersInRoom] = useState([]);
    const [inputPass, setInputPass] = useState("");
    const navigate = useNavigate();

    const [needNewPassword, setNeedNewPassword] = useState(false);
    const [sendMsgBeforeReady, setSendMsgBeforeReady] = useState("");

    const [time_waitingroom, setTime_waitingroom] = useState(time_waitingroom_default);
    const [time_redaction, setTime_redaction] = useState(time_redaction_default);
    const [time_evaluation, setTime_evaluation] = useState(time_evaluation_default);

    const [currentPhase, setCurrentPhase] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [sujet, setSujet] = useState('');
    const [isBig, setIsBig] = useState(false);
    const [endSession, setEndSession] = useState(false);

    const [tache, setTache] = useState("");
    const [isBanned, setIsBanned] = useState(false);  // Nouvel état pour gérer le bannissement

    const [parametres, setParametres] = useState({});
    const [type, setType] = useState('');
    const [redactionLongue, setRedactionLongue] = useState(false);

    useEffect(() => {
        const newTache = getTachePlan(currentPhase);
        setTache(newTache); // Mettre à jour l'état qui est passé à AfficheTitre
    }, [currentPhase]);

    const closeSession = async () => {
        const session_id = user.currentsession;
        const admin_id = user.id; // Assurez-vous que `user.id` est correctement défini et contient l'ID de l'administrateur
    
        try {
            const response = await fetch(`${serverPHP}closeSession.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ session_id, admin_id }), // Envoyer `session_id` et `admin_id` dans le corps de la requête
            });
    
            const data = await response.json();
    
            if (data.success) {
                if (debug_mode) console.log("Résultat après l'appel à closeSession.php. Normalement réservé à l'administrateur.");
                setEndSession(true);
            } else {
                console.error('Erreur:', data.error);
            }
        } catch (error) {
            console.error('Erreur lors de la requête:', error);
        }
    };
    

    const goNext = useCallback((isUserReady = null, forceAdmin = false) => {    //le isUserReady contient un id, et non un booléen
        if (debug_mode) console.log("Envoyé à updateSessionPhase", JSON.stringify({
            session_id: user.currentsession,
            anciennePhase: currentPhase,
            isUserReady: isUserReady,
            userId: user.id,
            forceAdmin: forceAdmin
        }));
        return new Promise((resolve, reject) => {
            fetch(`${serverPHP}updateSessionPhase.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    session_id: user.currentsession,
                    anciennePhase: currentPhase,
                    isUserReady: isUserReady,
                    userId: user.id,
                    forceAdmin: forceAdmin
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (debug_mode) console.log("data renvoyées", data);
                    if (data.success) {
                        if (data.nextphase) {
                            setUser(prevUser => ({ ...prevUser, isready: false }));
                            setUsersInRoom(prevUsers => {
                                const updatedUsers = prevUsers.map(u => ({ ...u, isReady: false }));
                                return updatedUsers;
                            });

                            setCurrentPhase(data.nouvellePhase);
                        }

                        if (data.close) {
                            if (debug_mode) console.log("On a reçu un close de updateSessionPhase. on lance closeSession")
                            setEndSession(true);
                        }

                        resolve(data);
                    } else {
                        console.error('Erreur lors de la mise à jour de la phase:', data.error);
                        reject(data.error);
                    }
                })
                .catch(error => {
                    console.error('Erreur lors de la requête de mise à jour de la phase:', error);
                    reject(error);
                });
        });
    }, [currentPhase, user.currentsession, closeSession]);

    useEffect(() => {
        if (currentPhase >= 19) {
            closeSession();
        }
    }, [currentPhase]);

    useEffect(() => {
        // Fetch session data on mount
        const fetchSessionData = async () => {
            try {
                const response = await fetch(`${serverPHP}getSession.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        session_id: user.currentsession,
                        user_id: user.id,
                        motDePasse: motDePasse
                    })
                });

                const data = await response.json();
                if (data.success) {
                    setNeedNewPassword(false);

                    // Définir si l'utilisateur est administrateur
                    setIsAdmin(data.isadmin);

                    // Mise à jour des nouveaux états
                    setType(data.type);
                    setParametres(data.parametres);
                    setRedactionLongue(data.redaction_longue);

                    setSujet(data.sujet);
                    if (debug_mode) console.log("résultat de getSession", data);
                    if (data.time_waitingroom != null) setTime_waitingroom(data.time_waitingroom);
                    if (data.time_redaction != null) setTime_redaction(data.time_redaction);
                    if (data.time_evaluation != null) setTime_evaluation(data.time_evaluation);

                    if (data.info.titre !== null && data.info.contenu !== null) { // s'il y a un message à afficher
                        setTitreInfo(data.info.titre);
                        setTexteInfo(<>{data.info.contenu}</>);
                        setShowInfo(true);
                    }

                    setCurrentPhase(data.phase);
                    if (data.closed === 1) {
                        if (debug_mode) console.log("On a regardé par getSession, et on constate qu'il y a un closed.")
                        setEndSession(true);
                    } else {
                        setDerniereMaj(data.dernieremaj); // Stocke le moment du dernier changement de phase
                        setUser({ ...user, isready: false });
                    }

                    setIsLoaded(true);
                } else if (data.error === 'password') {
                    if (motDePasse !== "") {
                        setTitreInfo("Erreur de mot de passe");
                        setTexteInfo(<>Le mot de passe indiqué ne correspond pas à celui de la session.</>);
                        setShowInfo(true);
                    }
                    setNeedNewPassword(true);
                    setIsLoaded(true);
                    console.error('Erreur de mot de passe: ', data.error);
                } else if (data.error === 'banned') {  // Gérer l'erreur "banned"
                    setIsBanned(true);
                    setIsLoaded(true);
                } else {
                    console.error('Erreur:', data.error);
                }
            } catch (error) {
                console.error('Failed to fetch session info:', error);
                setIsLoaded(true); // Même en cas d'erreur, permettre à l'UI de se rendre
            }
        };

        fetchSessionData();

        return () => {
            setUser(prevUser => ({ ...prevUser, isready: false }));
        };
    }, [user.currentsession, currentPhase, endSession, motDePasse, setIsLoaded, setUser, setSujet, setCurrentPhase, setDerniereMaj, setEndSession, setTitreInfo, setTexteInfo, setShowInfo, setNeedNewPassword]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Clean up any timers here
            navigator.sendBeacon(`${serverPHP}updateUser.php`, JSON.stringify({ ...user, currentsession: null }));
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            // Ensure all timers are cleaned up here as well
        };
    }, [setUser, user]);

    useEffect(() => {
        if (isBanned) {
            setTitreInfo("Avertissement");
            setTexteInfo(<>Vous avez été banni de la session.</>);
            setShowInfo(true);
            navigate("/");
        }
    }, [isBanned, setUser, setTitreInfo, setTexteInfo, setShowInfo, navigate]);

    return (
        <div className="session">
            {isLoaded ? (
                <>
                    {needNewPassword
                        ?
                        <>
                            La session {user.currentsession} est protégée par un mot de passe.
                            <div style={{ marginTop: '20px' }}>
                                <input
                                    type="password"
                                    value={inputPass}
                                    onChange={(e) => setInputPass(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setMotDePasse(inputPass);
                                        }
                                    }}
                                    placeholder="Mot de passe"
                                    style={{ height: '40px', marginRight: '20px' }}
                                />
                                <button
                                    className=""
                                    onClick={() => setMotDePasse(inputPass)}
                                    style={{ padding: '10px 20px' }}
                                >
                                    Ok
                                </button>
                            </div>
                        </>

                        :
                        <>
                            {endSession ? (
                                <ResultatFinal
                                    user={user}
                                    sujet={sujet}
                                    currentPhase={currentPhase}
                                    setTexteInfo={setTexteInfo}
                                    setTitreInfo={setTitreInfo}
                                    setShowInfo={setShowInfo}
                                    parametres={parametres}
                                    type={type}
                                    redactionLongue={redactionLongue}
                                />
                            ) : (
                                <>
                                    {currentPhase === 0 ? (
                                        <>
                                            <h3 className="sujet">{sujet}</h3>
                                            <WaitingRoom
                                                user={user}
                                                setUser={setUser}
                                                isAdmin={isAdmin}
                                            />
                                            <ChronoUsers
                                                user={user}
                                                setUser={setUser}
                                                phase={0}
                                                redaction={false}
                                                currentPhase={currentPhase}
                                                setCurrentPhase={setCurrentPhase}
                                                dureeSession={time_waitingroom}
                                                dernieremaj={derniereMaj}
                                                isBig={isBig}
                                                setIsBig={setIsBig}
                                                forcebig={true}
                                                goNext={goNext}
                                                usersInRoom={usersInRoom}
                                                setUsersInRoom={setUsersInRoom}
                                                setTexteInfo={setTexteInfo}
                                                setTitreInfo={setTitreInfo}
                                                setShowInfo={setShowInfo}
                                                setEndSession={setEndSession}
                                                sendMsgBeforeReady={""}
                                            />
                                        </>
                                    ) : currentPhase % 2 !== 0 ? (
                                        <>
                                            <AfficheTitre
                                                tache={tache}
                                                isAdmin={isAdmin}
                                                redaction={true}
                                                user={user}
                                                parametres={parametres}
                                                type={type}
                                                phase={currentPhase}
                                                setUser={setUser}
                                                goNext={goNext}
                                                closeSession={closeSession}
                                            />
                                            <Redaction
                                                user={user}
                                                setUser={setUser}
                                                phase={currentPhase}
                                                sujet={sujet}
                                                setSendMsgBeforeReady={setSendMsgBeforeReady}
                                                setTexteInfo={setTexteInfo}
                                                setTitreInfo={setTitreInfo}
                                                setShowInfo={setShowInfo}
                                                parametres={parametres}
                                                type={type}
                                                redactionLongue={redactionLongue}
                                            />
                                            <ChronoUsers
                                                user={user}
                                                setUser={setUser}
                                                phase={currentPhase}
                                                redaction={true}
                                                currentPhase={currentPhase}
                                                setCurrentPhase={setCurrentPhase}
                                                dureeSession={time_redaction}
                                                dernieremaj={derniereMaj}
                                                isBig={isBig}
                                                setIsBig={setIsBig}
                                                goNext={goNext}
                                                usersInRoom={usersInRoom}
                                                setUsersInRoom={setUsersInRoom}
                                                sendMsgBeforeReady={sendMsgBeforeReady}
                                                setTexteInfo={setTexteInfo}
                                                setTitreInfo={setTitreInfo}
                                                setShowInfo={setShowInfo}
                                                setEndSession={setEndSession}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <AfficheTitre
                                                tache={tache}
                                                isAdmin={isAdmin}
                                                redaction={false}
                                                user={user}
                                                parametres={parametres}
                                                type={type}
                                                phase={currentPhase}
                                                setUser={setUser}
                                                goNext={goNext}
                                                closeSession={closeSession} 
                                            />
                                            <Evaluation
                                                user={user}
                                                setUser={setUser}
                                                phase={currentPhase}
                                                phase_a_evaluer={currentPhase - 1}
                                                sujet={sujet}
                                                setShowInfo={setShowInfo}
                                                setTitreInfo={setTitreInfo}
                                                setTexteInfo={setTexteInfo}
                                                setEndSession={setEndSession}
                                                goNext={goNext}
                                                closeSession={closeSession}
                                                sendMsgBeforeReady={sendMsgBeforeReady}
                                                setSendMsgBeforeReady={setSendMsgBeforeReady}
                                                parametres={parametres}
                                                type={type}
                                                redactionLongue={redactionLongue}
                                            />
                                            <ChronoUsers
                                                user={user}
                                                setUser={setUser}
                                                phase={currentPhase}
                                                redaction={false}
                                                currentPhase={currentPhase}
                                                setCurrentPhase={setCurrentPhase}
                                                dureeSession={time_evaluation}
                                                dernieremaj={derniereMaj}
                                                isBig={isBig}
                                                setIsBig={setIsBig}
                                                goNext={goNext}
                                                usersInRoom={usersInRoom}
                                                setUsersInRoom={setUsersInRoom}
                                                setTexteInfo={setTexteInfo}
                                                setTitreInfo={setTitreInfo}
                                                setShowInfo={setShowInfo}
                                                sendMsgBeforeReady={sendMsgBeforeReady}
                                                setEndSession={setEndSession}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    }
                </>
            ) : (
                <>
                </>
            )}

        </div>
    );
};

export default Session;
