import React, { useState, useEffect, createContext, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Container from './Menu/Container';
import './App.css';
import { DialogContext } from './const/consts';
import { serverPHP, debug_mode } from './const/consts';

const App = ({ goTo = null }) => {
  const [user, setUser] = useState({
    id: null,
    name: '',
    image: null,
    color: '',
    role: 0,
    currentsession: null,
    isready: false
  });
  const [loading, setLoading] = useState(true);
  const [oldUser, setOldUser] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);

  const [creatingSession, setCreatingSession] = useState(false);
  const [joinSession, setJoinSession] = useState(false);
  const { id } = useParams();

  const initialLoad = useRef(true);

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    if (!userId) {
      if (debug_mode) console.log("Aucun id n'a été trouvé");
      createNewUser();
    } else {
      if (debug_mode) console.log("On a trouvé un id", userId);
      checkUserExists(userId);
    }

    if (goTo === "nouvellesession") {
      setCreatingSession(true);
      setJoinSession(false);
      setUser(prevUser => ({ ...prevUser, currentsession: null }));
    } else if (goTo === "listesessions") {
      setJoinSession(true);
      setCreatingSession(false);
      setUser(prevUser => ({ ...prevUser, currentsession: null }));
    } else if (goTo === "session" && user.currentsession !== id) {
      setUser(prevUser => ({ ...prevUser, currentsession: id }));
    } else if (!goTo) {
      setCreatingSession(false);
      setJoinSession(false);
      setUser(prevUser => ({ ...prevUser, currentsession: null }));
    }

  }, [goTo, id]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return; // On quitte l'effet après le premier rendu
    }

    if (user && oldUser && user.id !== null && JSON.stringify(user) !== JSON.stringify(oldUser)) {
      updateUserInDatabase(user);
    }
    setOldUser(user);
  }, [user]);

  const checkUserExists = (userId) => {
    const attemptCheckUserExists = () => {
      fetch(serverPHP + `checkUser.php?id=${userId}`, {
        method: 'GET'
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        })
        .then(data => {
          if (data.exists) {
            if (debug_mode) console.log("Les données de l'utilisateur existent, chargement...");
            initialLoad.current = true; // Prévenir la mise à jour lors du chargement initial des données
            
            let updatedUser = data.user;
            if (goTo === "session" && id) {
              if (debug_mode) console.log("on rentre dans la session", user.currentsession, id);
              if (user.currentsession !== id) {
                if (debug_mode) console.log("on change bien le rôle");
                updatedUser = { ...data.user, currentsession: id, role: 0 };
              } else {
                updatedUser = { ...data.user, currentsession: id };
              }
            }
            setUser(updatedUser);
            setLoading(false); // Mettre loading à false après avoir récupéré l'utilisateur
          } else {
            createNewUser();
          }
        })
        .catch(error => {
          console.error('Erreur lors de la vérification de l\'existence de l\'utilisateur :', error);
          retryAfterDelay(attemptCheckUserExists);
        });
    };

    attemptCheckUserExists();
  };

  const retryAfterDelay = (func, delay = 10000) => {
    setTimeout(func, delay);
  };

  const createNewUser = () => {
    const attemptCreateNewUser = () => {
      let sessionId = (goTo == "session" && id) ? id : null;

      // Envoyer les données au serveur pour créer un nouvel utilisateur
      createUserInDatabase(sessionId)
        .then(newUser => {
          if (debug_mode) console.log("L'utilisateur est bien créé");
          localStorage.setItem('userId', newUser.id);
          setUser(newUser);
          setLoading(false); // Mettre loading à false après la mise à jour de l'utilisateur
          if (debug_mode) console.log("Utilisateur défini dans le useState, création", newUser);
        })
        .catch(error => {
          console.error('Erreur lors de la création de l\'utilisateur :', error);
          retryAfterDelay(attemptCreateNewUser);
        });
    };

    attemptCreateNewUser();
  };

  const createUserInDatabase = (sessionId) => {
    const attemptCreateUserInDatabase = () => {
      return fetch(serverPHP + 'createUser.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ currentsession: sessionId })
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        })
        .then(data => {
          return data.user;
        })
        .catch(error => {
          console.error('Erreur lors de la création de l\'utilisateur :', error);
          retryAfterDelay(attemptCreateUserInDatabase);
        });
    };

    return attemptCreateUserInDatabase();
  };

  const updateUserInDatabase = (user) => {
    const attemptUpdateUserInDatabase = () => {
      return fetch(serverPHP + 'updateUser.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        })
        .catch(error => {
          console.error('Erreur lors de la mise à jour de l\'utilisateur :', error);
          retryAfterDelay(attemptUpdateUserInDatabase);
        });
    };

    return attemptUpdateUserInDatabase();
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <DialogContext.Provider value={[dialogVisible, setDialogVisible]}>
      <div className="App">
        {user.id !== null ? (
          <Container user={user} setUser={setUser} creatingSession={creatingSession} setCreatingSession={setCreatingSession} joinSession={joinSession} setJoinSession={setJoinSession} />
        ) : (
          <p>Aucun utilisateur trouvé.</p>
        )}
      </div>
    </DialogContext.Provider>
  );
}

export default App;
