import React, { useState } from 'react';
import './shareurl.css'; // Nouveau fichier CSS pour ce composant
import QRCode from 'qrcode.react';

const ShareURL = () => {
    const [showShareDiv, setShowShareDiv] = useState(false);

    const handleShareButtonClick = (e) => {
        e.stopPropagation(); // Empêche la propagation pour éviter la fermeture immédiate
        setShowShareDiv(true);
    };

    // Assurez-vous que l'URL commence par http:// ou https://
    const fullUrl = window.location.href.startsWith('http') ? window.location.href : `http://${window.location.href}`;
    const currentUrl = fullUrl.replace(/^https?:\/\//, ''); // URL sans le schéma pour l'affichage

    // Vérification si l'URL contient "/session/[idsession]"
    const match = currentUrl.match(/\/session\/(\w+)/);
    const sessionId = match ? match[1] : null;

    return (
        <div>
            <button className="cadreshare" onClick={handleShareButtonClick} style={{marginBottom:0, marginTop:'10px'}}>
                Partager l'URL
            </button>

            {showShareDiv && (
                <div className="share-overlay" onClick={() => setShowShareDiv(false)}>
                    <div className="share-overlay-header">
                        <span className="share-url">
                            <div>Page en cours :</div>
                            {sessionId ? (
                                <>
                                    {currentUrl.split(`/session/${sessionId}`)[0]}
                                    /session/<strong style={{ color: '#195da9' }}>{`${sessionId}`}</strong>
                                    {currentUrl.split(`/session/${sessionId}`)[1]}
                                </>
                            ) : (
                                currentUrl
                            )}
                        </span>
                    </div>
                    <div className="share-overlay-body">
                        <div className="qrcode-container">
                            <QRCode value={fullUrl} size={1024} /> {/* Utilisez fullUrl ici */}
                        </div>
                    </div>
                    <div className="share-overlay-footer">
                        Cliquez n'importe où pour fermer
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShareURL;
