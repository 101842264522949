import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './index.css'

import copy from './assets/copy.svg';
import docx from './assets/docx.svg';
import down_arrow from './assets/down_arrow.svg';
import evaluation from './assets/evaluation.svg';
import fleche_d from './assets/fleche_d.svg';
import fleche_d_r from './assets/fleche_d_r.svg';
import fleche_g from './assets/fleche_g.svg';
import fleche_g_r from './assets/fleche_g_r.svg';
import home from './assets/home.svg';
import info from './assets/info.svg';
import key from './assets/key.svg';
import medaille_argent from './assets/medaille_argent.svg';
import medaille_bronze from './assets/medaille_bronze.svg';
import medaille_or from './assets/medaille_or.svg';
import redaction from './assets/redaction.svg';
import redcross from './assets/redcross.svg';
import send from './assets/send.svg';
import up_arrow from './assets/up_arrow.svg';
import visage_m1 from './assets/visage_m1.svg';
import visage_m1g from './assets/visage_m1g.svg';
import visage_m2 from './assets/visage_m2.svg';
import visage_m2g from './assets/visage_m2g.svg';
import visage_n from './assets/visage_n.svg';
import visage_ng from './assets/visage_ng.svg';
import visage_p1 from './assets/visage_p1.svg';
import visage_p1g from './assets/visage_p1g.svg';
import visage_p2 from './assets/visage_p2.svg';
import visage_p2g from './assets/visage_p2g.svg';


import AppRouter from './router';

function preloadImages(...images) {
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
}


preloadImages(copy, docx, down_arrow, evaluation, fleche_d, fleche_d_r, fleche_g, fleche_g_r, home, info, key, medaille_argent, medaille_bronze, medaille_or, redaction,redcross, send, up_arrow, visage_m1,visage_m1g,visage_m2,visage_m2g,visage_n,visage_ng,visage_p1,visage_p1g,visage_p2,visage_p2g );


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Suppression de <React.StrictMode>
  <AppRouter />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
