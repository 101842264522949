import React, { useEffect, useState, useCallback, useRef } from 'react';
import './SessionSelector.css';
import { serverPHP } from '../const/consts';
import cle20 from '../assets/key.svg';
import { useNavigate } from 'react-router-dom';
import PhaseTitle from '../composants/PhaseTitle'; // Nouveau composant pour gérer l'affichage des phases

const SessionSelector = ({ user, setUser }) => {
    const [sessions, setSessions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showClosed, setShowClosed] = useState(false);
    const navigate = useNavigate();
    const previousSessionsRef = useRef([]);

    const fetchSessions = useCallback(() => {
        fetch(serverPHP + 'getAllSessions.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && Array.isArray(data.sessions)) {
                    const newSessions = data.sessions;
                    if (JSON.stringify(newSessions) !== JSON.stringify(previousSessionsRef.current)) {
                        setSessions(newSessions);
                        previousSessionsRef.current = newSessions;
                    }
                } else {
                    console.error('Format de réponse inattendu:', data);
                }
            })
            .catch(error => console.error('Erreur:', error));
    }, []);

    useEffect(() => {
        fetchSessions(); // Fetch immediately on mount
        const intervalId = setInterval(fetchSessions, 5000); // Set interval to fetch every 5 seconds

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, [fetchSessions]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handleShowClosedChange = () => {
        setShowClosed(!showClosed);
        setCurrentPage(1);
    };

    const filteredSessions = sessions.filter(session =>
        (showClosed ? session.closed : !session.closed) &&
        (session.sujet.toLowerCase().includes(searchQuery.toLowerCase()) ||
            session.session_id.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const sessionsPerPage = 5;
    const totalPages = Math.ceil(filteredSessions.length / sessionsPerPage);

    const renderSessions = useCallback((sessions, page) => {
        const startIndex = (page - 1) * sessionsPerPage;
        const paginatedSessions = sessions.slice(startIndex, startIndex + sessionsPerPage);

        return paginatedSessions.map(session => {
            let additionalInfo = '';
            const jsonParametres = JSON.parse(session.parametres);

            if (session.type === 'plan') {
                const parties = jsonParametres.parties;
                const sousparties = jsonParametres.sousparties;

                additionalInfo = `Plan (${parties}, ${sousparties})`;
            } else if (session.type === 'redaction') {
                const phases = jsonParametres.phases;

                if (phases === 0) {
                    additionalInfo = 'Phases illimitées';
                } else if (phases === 1) {
                    additionalInfo = 'Phase unique';
                } else {
                    additionalInfo = `${phases} phases`;
                }
            }

            return (
                <div
                    key={session.session_id}
                    className="session-item"
                    onClick={() => navigate("/session/" + session.session_id)}
                >
                    <div className="session-header">
                        <div>
                            <span className="session-id">Session : <span style={{ color: '#195da9' }}>{session.session_id}</span></span>{' '}
                        </div>
                        <span className="session-subject">{session.sujet}</span>
                    </div>
                    <div className="session-body">
                        <div style={{ display: 'flex' }}>
                            <span className="session-info">{additionalInfo}</span>
                            {session.password && <span className="session-password"><img src={cle20} className="cle" alt="cle" /></span>}
                        </div>
                        <span className={`session-phase ${session.phase === 0 ? 'waitingroom' : session.closed ? 'closed' : 'encours'}`}>
                            <PhaseTitle phase={session.phase} type={session.type} parametres={session.parametres} />
                        </span>
                    </div>
                </div>
            );
        });
    }, [navigate]);

    const renderPaginationButtons = () => {
        const pages = [];
        const maxVisiblePages = 3;

        const addPageButton = (page) => {
            pages.push(
                <button
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    className={page === currentPage ? 'boutonChiffreOn' : 'boutonChiffreOff'}
                >
                    {page}
                </button>
            );
        };

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                addPageButton(i);
            }
        } else {
            addPageButton(1);

            if (currentPage > 2) {
                pages.push(<span key="start-ellipsis" className="ellipsis">...</span>);
            }

            let startPage = Math.max(currentPage - 1, 2);
            let endPage = Math.min(currentPage + 1, totalPages - 1);

            for (let i = startPage; i <= endPage; i++) {
                addPageButton(i);
            }

            if (currentPage < totalPages - 1) {
                pages.push(<span key="end-ellipsis" className="ellipsis">...</span>);
            }

            addPageButton(totalPages);
        }

        return pages;
    };

    return (
        <>
            <div style={{ width: '100%' }}>
                <div className="session-list">
                    <input
                        type="text"
                        className="session-search"
                        placeholder="Rechercher une session ou un titre"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '40px' }}>
                        <label className="sessionsterminees">
                            <input
                                type="checkbox"
                                checked={showClosed}
                                onChange={handleShowClosedChange}
                            /> Sessions terminées
                        </label>
                    </div>
                    {searchQuery && filteredSessions.length === 0 && (
                        <div className="no-results">
                            Aucune entrée trouvée, veuillez vérifier votre recherche
                        </div>
                    )}
                    {!searchQuery && sessions.length === 0 && (
                        <div className="no-results">
                            Aucune session n'est actuellement en cours
                        </div>
                    )}
                    {renderSessions(filteredSessions, currentPage)}
                    {totalPages > 1 && (
                        <div className="pagination">
                            {renderPaginationButtons()}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SessionSelector;
