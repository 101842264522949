import React, { useState, useEffect } from 'react';
import './NouvelleSession.css';
import { useNavigate } from 'react-router-dom';
import { serverPHP, time_waitingroom_default, time_redaction_default, time_evaluation_default } from '../const/consts';
import InfoImg from '../assets/info.svg';
import { debug_mode } from '../const/consts';

const LOCAL_STORAGE_KEY = 'sessionParams';

const NouvelleSession = ({ user, setTexteInfo, setTitreInfo, setShowInfo, setMotDePasse }) => {
  const [sujet, setSujet] = useState('');
  const [errors, setErrors] = useState({});

  const [sessionType, setSessionType] = useState('une_redaction');
  const [phases, setPhases] = useState(2);
  const [noLimitPhases, setNoLimitPhases] = useState(false);
  const [parts, setParts] = useState(3);
  const [subParts, setSubParts] = useState(2);
  const [usePassword, setUsePassword] = useState(false);  // Désactivé par défaut
  const [password, setPassword] = useState('');  // Initialisé à une chaîne vide
  const [redactionType, setRedactionType] = useState('longues');
  const [customizeSession, setCustomizeSession] = useState(false);
  const [timeSettings, setTimeSettings] = useState({
    waitingRoom: { minutes: time_waitingroom_default, noLimit: false },
    redaction: { minutes: time_redaction_default, noLimit: false },
    evaluation: { minutes: time_evaluation_default, noLimit: false }
  });

  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleCreateSession();
    }
  };

  useEffect(() => {
    const savedParams = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedParams) {
      const parsedParams = JSON.parse(savedParams);
      setSessionType(parsedParams.sessionType || 'une_redaction');
      setPhases(parsedParams.phases || 2);
      setNoLimitPhases(parsedParams.noLimitPhases || false);
      setParts(parsedParams.parts || 3);
      setSubParts(parsedParams.subParts || 2);
      setUsePassword(false);  // Désactiver le mot de passe par défaut
      setPassword('');  // Ne pas charger de mot de passe
      setRedactionType(parsedParams.redactionType || 'longues');
      setCustomizeSession(parsedParams.customizeSession || false);
      setTimeSettings(parsedParams.timeSettings || {
        waitingRoom: { minutes: time_waitingroom_default, noLimit: false },
        redaction: { minutes: time_redaction_default, noLimit: false },
        evaluation: { minutes: time_evaluation_default, noLimit: false }
      });
    }
  }, []);


  useEffect(() => {
    const params = {
      sessionType,
      phases,
      noLimitPhases,
      parts,
      subParts,
      usePassword: false,  // Ne pas sauvegarder l'utilisation du mot de passe
      password: '',  // Ne pas sauvegarder de mot de passe
      redactionType,
      customizeSession,
      timeSettings,
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(params));
  }, [sessionType, phases, noLimitPhases, parts, subParts, redactionType, customizeSession, timeSettings]);


  const handleInfoClick = (message) => {
    setTexteInfo(message);
    setTitreInfo("Information");
    setShowInfo(true);
  };

  useEffect(() => {
    if (sessionType === 'plan') {
      setRedactionType('courtes');
    } else if (sessionType === 'une_redaction' || sessionType === 'plusieurs_redactions') {
      setRedactionType('longues');
    }
  }, [sessionType]);

  const handleCreateSession = () => {
    const newErrors = {};

    if (!sujet.trim()) {
      newErrors.sujet = "Le sujet ne peut pas être vide. Veuillez entrer un sujet pour la session.";
    }

    if (usePassword && !password.trim()) {
      newErrors.password = "Vous avez activé l'option mot de passe, mais vous n'avez pas entré de mot de passe. Veuillez entrer un mot de passe ou désactiver cette option.";
    }

    const phasesWithErrors = ['waitingRoom', 'redaction', 'evaluation'].filter((phase) => {
      if (!timeSettings[phase].noLimit) {
        const minutes = parseInt(timeSettings[phase].minutes, 10);
        if (isNaN(minutes) || minutes < 1) {
          newErrors[phase] = "La valeur des minutes doit être un entier supérieur ou égal à 1.";
          return true;
        }
      }
      return false;
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      const firstErrorKey = Object.keys(newErrors)[0];
      handleInfoClick(newErrors[firstErrorKey]);
      return;
    }

    // Préparation des données à envoyer
    let type = sessionType === 'plan' ? 'plan' : 'redaction';

    let parametres;
    if (type === 'redaction') {
      parametres = {
        phases: noLimitPhases ? 0 : phases
      };
      if (sessionType === 'une_redaction') {
        parametres.phases = 1;
      }
    } else if (type === 'plan') {
      parametres = {
        parties: parts,
        sousparties: subParts
      };
    }

    let payload = {
      admin: user.id,
      sujet: sujet.trim(),
      type,
      parametres: JSON.stringify(parametres)
    };

    // Gérer les paramètres avancés ou non
    if (customizeSession) {
      payload.password = password.trim() || "";
      payload.redaction_longue = redactionType === 'longues';
      payload.time_waitingroom = timeSettings.waitingRoom.noLimit ? 0 : timeSettings.waitingRoom.minutes * 60;
      payload.time_redaction = timeSettings.redaction.noLimit ? 0 : timeSettings.redaction.minutes * 60;
      payload.time_evaluation = timeSettings.evaluation.noLimit ? 0 : timeSettings.evaluation.minutes * 60;
    } else {
      payload.password = "";  // Pas de mot de passe si paramètres avancés non activés
      payload.redaction_longue = type === 'redaction';
      payload.time_waitingroom = time_waitingroom_default * 60;
      payload.time_redaction = time_redaction_default * 60;
      payload.time_evaluation = time_evaluation_default * 60;
    }


    if (debug_mode) console.log(payload);
    // Envoi des données à createSession.php

    fetch(`${serverPHP}createSession.php`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        setMotDePasse(password.trim());
        navigate("/session/" + data.session_id);
      })
      .catch((error) => console.error('Erreur lors de la création de la session :', error));
  };


  return (
    <div className="nouvelle-session-container">
      <div className="inline-container">
        <label className="label_new">Sujet :</label>
        <input
          type="text"
          className={`subject-input_new ${errors.sujet ? 'input-error' : ''}`}
          value={sujet}
          onChange={(e) => setSujet(e.target.value)}
          onKeyDown={handleKeyDown}  // <-- Ajoutez cette ligne
          style={{ flex: 1 }}
        />
      </div>
      <div className="reglages_simples">
        <div className="inline-container type_session">
          <label className="label_new">Type de session :</label>
          <div className="centrerVerticalement addTopMobile">
            <select
              value={sessionType}
              onChange={(e) => setSessionType(e.target.value)}
              className="select_new"
            >
              <option value="une_redaction">Une seule phase</option>
              <option value="plusieurs_redactions">Plusieurs phases</option>
              <option value="plan">Construction de plan</option>
            </select>
            <img
              src={InfoImg}
              alt="info"
              style={{ width: '35px', height: '35px', marginLeft: '15px', cursor: 'pointer' }}
              onClick={() => handleInfoClick(
                sessionType === 'une_redaction' ?
                  "C'est le type de session le plus simple : les rédacteurs composent un texte, les évaluateurs votent, et la session se termine en déterminant le texte qui a été le plus apprécié." :
                  sessionType === 'plusieurs_redactions' ?
                    "Dans ce type de session, on enchaîne plusieurs phases de rédaction/évaluation. Il est possible de choisir un nombre illimité de phases ; dans ce cas, c'est soit l'administrateur qui décide de mettre fin à la session, soit les rédacteurs qui arrêtent de contribuer." :
                    sessionType === 'plan' ?
                      "Dans ce type de session, on spécifie un certain nombre de parties et de sous-parties, pour donner forme à un plan. Chaque partie et chaque sous-partie donne lieu à une phase de rédaction/évaluation." :
                      "")}
            />
          </div>
        </div>

        {sessionType === 'plusieurs_redactions' && (
          <div className="inline-container parametresSelect">
            <div >
              <label className="showwideInline">Avec</label>
              <input
                type="text"
                className={`phases-input ${errors.phases ? 'input-error' : ''}`}
                value={phases}
                onChange={(e) => {
                  const value = Math.max(2, Number(e.target.value));
                  setPhases(value);
                }}
                disabled={noLimitPhases}
                maxLength="2"
                style={{ width: '50px', marginRight: '5px', marginLeft: '10px' }}
              />
              <label className="label_new rightIfFull">phases</label>
            </div>
            <input
              type="checkbox"
              checked={noLimitPhases}
              onChange={() => setNoLimitPhases(!noLimitPhases)}
              className="checkbox_new"
            />
            <label className="label_new">Pas de limite</label>
          </div>
        )}

        {sessionType === 'plan' && (
          <div className="inline-container parametresSelect">
            <label className="label_new showwideInline">Avec</label>
            <select value={parts} onChange={(e) => setParts(Number(e.target.value))} className="select_new smallMarginRight" style={{ marginRight: '10px' }}>
              {[1, 2, 3, 4].map((num) => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
            <label className="label_new" style={{ marginRight: 0 }}>{parts > 1 ? ' parties' : ' partie'}</label>
            <label className="label_new showwideInline" style={{ marginLeft: '6px' }}> et </label>
            <label className="label_new showmobileInline"> </label>
            <select value={subParts} onChange={(e) => setSubParts(Number(e.target.value))} className="select_new smallMarginRight" style={{ marginRight: '10px' }}>
              {[0, 1, 2, 3, 4].map((num) => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
            <label className="label_new">{subParts > 1 ? ' sous-parties' : ' sous-partie'}</label>
          </div>
        )}
      </div>
      <div className="inline-container center-content">
        <input
          type="checkbox"
          checked={customizeSession}
          onChange={() => setCustomizeSession(!customizeSession)}
          className="checkbox_new"
        />
        <label className="label_new">Paramètres avancés</label>
      </div>

      {customizeSession && (
        <div className="customize-options">
          <div className="password-section inline-container type_session" >
            <div style={{ display: 'flex' }}>
              <input
                type="checkbox"
                checked={usePassword}
                onChange={() => setUsePassword(!usePassword)}
                className="checkbox_new"
              />
              <label className="label_new marginRight40pxIfFull">Mot de passe</label>
            </div>
            <input
              type="password"
              className={`password-input_new addTopMobile ${errors.password ? 'input-error' : ''}`}
              value={password}
              disabled={!usePassword}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="inline-container marginTop30pxIfMobile type_session" style={{ marginBottom: '30px' }}>
            <label className="label_new">Type de rédaction :</label>
            <div className="centrerVerticalement addTopMobile">
              <select
                value={redactionType}
                onChange={(e) => setRedactionType(e.target.value)}
                className="select_new"
              >
                <option value="courtes">Une phrase</option>
                <option value="longues">Rédactions longues</option>
              </select>
              <img
                src={InfoImg}
                alt="info"
                style={{ width: '35px', height: '35px', marginLeft: '15px', cursor: 'pointer' }}
                onClick={() => handleInfoClick(
                  redactionType === 'courtes' ?
                    "Avec le réglage \"une phrase\", les textes rédigés par les utilisateurs sont limités à 150 caractères. C'est idéal pour construire un plan." :
                    redactionType === 'longues' ?
                      "Dans les rédactions longues, les textes rédigés par les utilisateurs n'ont pas de limite de caractères. Ils ne pourront cependant pas être affichés en entier lors des synthèses de la session." :
                      "")}
              />
            </div>
          </div>

          <div className="time-settings-section">
            <label className="label_new">Temps d'attente avant l'étape suivante</label>
            <div style={{ marginTop: '40px' }}>
              <div className="time-settings-table">
                <div className="time-settings-row">
                  <div className="time-settings-header"></div>
                  <div className="time-settings-header">Minutes</div>
                  <div className="time-settings-header">Pas de limite</div>
                </div>
                {['waitingRoom', 'redaction', 'evaluation'].map((phase) => (
                  <div key={phase} className="time-settings-row">
                    <div className="time-settings-phase">
                      {phase === 'waitingRoom' && 'Salle d\'attente'}
                      {phase === 'redaction' && 'Rédaction'}
                      {phase === 'evaluation' && 'Évaluation'}
                    </div>
                    <div className="time-settings-column">
                      <input
                        type="text"
                        className={`time-input ${errors[phase] ? 'input-error' : ''}`}
                        value={timeSettings[phase].minutes}
                        onChange={(e) => {
                          const value = e.target.value;
                          setTimeSettings({
                            ...timeSettings,
                            [phase]: { ...timeSettings[phase], minutes: value },
                          });
                        }}
                        disabled={timeSettings[phase].noLimit}
                      />
                    </div>
                    <div className="time-settings-column">
                      <input
                        type="checkbox"
                        checked={timeSettings[phase].noLimit}
                        style={{ marginRight: 0 }}
                        onChange={() => {
                          setTimeSettings({
                            ...timeSettings,
                            [phase]: { ...timeSettings[phase], noLimit: !timeSettings[phase].noLimit },
                          });
                        }}
                        className="checkbox_new"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <button className="midbutton" onClick={handleCreateSession} style={{ margin: 'auto' }}>
        Créer la session
      </button>
    </div>
  );

};

export default NouvelleSession;
