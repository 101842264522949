import Popup from "./popup";

function InfoTitre({ children, titre, onClose, big = false, priority = false }) {
    return (
      <>
        <Popup onClose={onClose} big={big} priority={priority}>
          <div style={{ textAlign: 'center' }}>
            {big ?
            <h3 style={{ fontWeight: 'bold',marginTop:'0px',marginBottom:'30px' }}>{titre}</h3>

            :
            <h1 style={{ fontWeight: 'bold',marginTop:'0px',marginBottom:'30px' }}>{titre}</h1>
}
            {children}
          </div>
        </Popup>
      </>
    );
  }
  

export default InfoTitre;