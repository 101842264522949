import React, { useEffect, useState } from 'react';
import evaluation_img from '../assets/evaluation.svg';
import redaction_img from '../assets/redaction.svg';
import './AfficheTitre.css';
import InfoTitre from '../composants/infotitre';
import Administrateur from '../phases/Administrateur';


const romanNumerals = ["I", "II", "III", "IV", "V", "VI"]; // Extend as necessary
const alphabet = ['a', 'b', 'c', 'd']; // Extend as necessary

// Fonction pour déterminer le préfixe en fonction de la phase
const getPrefixForPhase = (phase, parties, sousparties) => {
    const phasesPerPartie = 1 + sousparties; // 1 phase for the "Partie" + sousparties phases
    const phaseGroupIndex = Math.floor((phase - 1) / 2); // Determine the phase group index (0-based)
    const partieIndex = Math.floor(phaseGroupIndex / phasesPerPartie); // Determine which "Partie" it is
    const subPartieIndex = phaseGroupIndex % phasesPerPartie; // Determine which "Sous-partie" it is within that "Partie"
    if (subPartieIndex === 0) {
        return `Partie ${romanNumerals[partieIndex]}`;
    } else {
        return `Sous-partie ${romanNumerals[partieIndex]}.${alphabet[subPartieIndex - 1]}`;
    }
};

const AfficheTitre = ({ tache, redaction = true, user, parametres, type, phase, isAdmin = false, setUser, goNext, closeSession }) => {
    const [parties, setParties] = useState(0);
    const [sousparties, setSousparties] = useState(0);
    const [titreAffiche, setTitreAffiche] = useState('');
    const [showInfo, setShowInfo] = useState(false);


    useEffect(() => {
        if (type === "plan") {
            const parsedParametres = typeof parametres === 'string' ? JSON.parse(parametres) : parametres;
            setParties(parsedParametres.parties || 0);
            setSousparties(parsedParametres.sousparties || 0);
        }
    }, [parametres, type]);

    useEffect(() => {
        if (type === "plan") {
            const prefix = getPrefixForPhase(phase, parties, sousparties);
            setTitreAffiche(prefix);
        } else if (type === "redaction") {
            setTitreAffiche(`Phase ${Math.floor((phase + 1) / 2)}`);
        }
    }, [parties, sousparties, type, phase]);

    return (
        <div className="cadre_tache" style={redaction ? { backgroundColor: "#e5d1ff" } : { backgroundColor: "#ffd0d0" }}>
            <div style={{ width: '80px' }}>
                <img src={redaction ? redaction_img : evaluation_img} className="icone_tache" alt="Icone de tâche" />
            </div>
            <div style={{ width: '100%', lineHeight: '1.4rem' }} className="titre_mobile cadretache">
                <div style={{ fontWeight: 'bold' }} className="titre_mobile">{redaction ? "Rédaction" : "Évaluation"}</div>
                <div>{titreAffiche}</div>
                {isAdmin &&
                <>
                <button className="adminbutton" onClick={() => setShowInfo(true)}>Administrateur</button>
                </>
                }
            </div>
            {showInfo && (
          <InfoTitre titre={"Actions administrateur"} big={true} onClose={() => setShowInfo(false)} priority={true}>
            <Administrateur
                                                user={user}
                                                setUser={setUser}
                                                phase={phase}
                                                goNext={goNext}
                                                closeSession={closeSession}
                                                redaction={phase % 2 !== 0}
                                                setShowInfoAdmin={setShowInfo}
                                            />
          </InfoTitre>
        )}
        </div>
    );
};

export default AfficheTitre;
