import React, { useState, useEffect, useRef, useContext } from 'react';
import './tooltip.css';
import { DialogContext } from '../const/consts';

const Tooltip = ({ text, children, direction = 'up', color = 'rgba(0, 0, 0, 0.8)', textColor= '#ccc', time = 500, fontweight=500 }) => {

  const context = useContext(DialogContext);
  if (!context) throw new Error('dialogContext is undefined');
  const [dialogVisible] = context;

  const [isOpen, setIsOpen] = useState(false);
  const [dialogWasOpened, setDialogWasOpened] = useState(false);
  const timeoutRef = useRef();

  const handleMouseEnter = () => {
    setDialogWasOpened(dialogVisible);
    if (children) {
      timeoutRef.current = window.setTimeout(() => {
        setIsOpen(true);
      }, time);
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [dialogVisible]);

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    // Check if children are still valid or existing
    if (!children) {
      setIsOpen(false);
    }
  }, [children]);

  useEffect(() => {
    return () => {
      // Cleanup timeout and ensure tooltip is closed
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
      setIsOpen(false);
    };
  }, []);

  return (
    <div className="tooltip-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children || null} {/* Ensure children is null if condition is not met */}
      {isOpen && (dialogWasOpened === dialogVisible) && (
        <div className={`tooltip ${direction}`} style={{ backgroundColor: color, color: textColor, fontWeight: fontweight }}>
          <div 
            className="tooltip-arrow" 
            style={{ borderColor: direction === 'up' ? `${color} transparent transparent transparent` : `transparent transparent ${color} transparent` }}
          />
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
