import React, { useState } from 'react';
import './popup.css';
import redCrossIcon from '../assets/redcross.svg'; // Importation de l'image

function Popup({ children, onClose, big=false, priority = false }) {
  const [showPopup, setShowPopup] = useState(true);
  
  const fermer = () => {
    onClose();
    setShowPopup(false);
  }
  
  return (
    <>
      {showPopup &&
        <div className="popup-container" style={{zIndex : priority ? 3 : 2}}>
          <div className="popup-overlay" onClick={fermer}></div>
          <div className={big ? 'popup-content-BIG' : 'popup-content'}>
            <img 
              src={redCrossIcon} 
              alt="Fermer" 
              className="close-icon" 
              onClick={fermer} 
            />
            {children}
          </div>
        </div>
      }
    </>
  );
}

export default Popup;
