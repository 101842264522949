import React, { useState, useEffect } from 'react';
import Pseudo_utilisateur from '../composants/pseudo_utilisateur';
import Popup from '../composants/popup';
import RoleSelector from '../composants/roleselector';
import home40 from '../assets/home.svg';
import './header.css';
import { useNavigate } from 'react-router-dom';
import ShareURL from '../composants/ShareURL';
import InfoTitre from '../composants/infotitre';
import Tooltip from '../Tooltip/tooltip';


const Header = ({ user, setUser, joinSession, setJoinSession, creatingSession, setCreatingSession, isAdmin, setIsAdmin }) => {
  const [showSettings, setShowSettings] = useState(false);
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState(false);
  const [inputError, setInputError] = useState(false);


  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  const resetFields = () => {
    setUserName(user.name);
    setRole(user.role);
    setInputError(false);
  };

  const handleSave = async () => {
    if (userName.length > 25) {
      setInputError(true);
      setTimeout(() => setInputError(false), 1500);
      return;
    }
    setUser({ ...user, name: userName, role: role });
    setShowSettings(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (showSettings) {
      resetFields();
    }
  }, [showSettings, user.name, user.role]);

  return (
    <div className="header">
      <div className="pseudoHeader" onClick={() => setShowSettings(true)}>

        <Pseudo_utilisateur user={user} height={40} showSession={true} />

      </div>
      <div>
          {(user.currentsession || joinSession || creatingSession) && (
          <img src={home40} alt="Home" className="settings-icon" onClick={goHome} />
        )}

      </div>

      {showSettings && (
        <InfoTitre

          titre={"Paramètres de l'utilisateur"}
          onClose={() => {
            setShowSettings(false);
            resetFields();
          }}
        >
          <div className="settings-form">

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
              <div style={{ width: '80px', textAlign: 'left' }}>
                Pseudo :
              </div>
              <input
                type="text"
                className={`centeredText ${inputError ? 'input-error' : ''}`}
                value={userName}
                style={{ height: '35px', flexGrow: 1 }}
                onChange={(e) => {
                  if (e.target.value.length <= 25) {
                    setUserName(e.target.value);
                  } else {
                    setInputError(true);
                    setTimeout(() => setInputError(false), 1500);
                  }
                }}
                onKeyDown={handleKeyDown}
              />
              <button onClick={handleSave} style={{ margin: 0, marginLeft: '15px', width: '40px', height: '35px', borderRadius: '10px', padding: 0 }}>
                OK
              </button>
            </div>



            {user.currentsession !== null && user.currentsession != undefined &&
              <div style={{ display: 'flex', displayDirection: 'row', marginTop: '20px', alignItems: 'center' }}>
                <div style={{ display: 'flex', width: '80px', justifyContent: 'left' }}>
                  Rôle :
                </div>
                <label style={{ flexGrow: 1 }}>
                  <RoleSelector user={user} fullWidth={true} setUser={setUser} isAdmin={isAdmin} onValueChange={() => setShowSettings(false)} />
                </label>
              </div>
            }
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}><ShareURL /></div>

          </div>
        </InfoTitre>
      )}
    </div>
  );
};

export default Header;
