import images from '../const/images'; // Import du tableau d'images
import './icone_utilisateur.css'

const imagePath = '/iconsfr/'; // Chemin relatif vers le dossier public/images

function Icone_utilisateur({ user, height, stack=false, suspension=false }) {
    
    return (
        <div style={{
            width: `${height}px`,
            height: `${height}px`,
            backgroundColor: `#${user.color}`
        }}
        className={`divimage ${stack ? 'stacked-div' : ''}`}
        >
            {suspension ?
            <> <img src={`${imagePath}suspension.png`} alt="Plus d'utilisateurs" className='imagestyle' />
            </>
            : <>
            {user.image &&
                <img src={`${imagePath}${images[user.image - 1]}.png`} alt={images[user.image - 1]} className='imagestyle' />
            }
            </>
            }
        </div>
    );
}

export default Icone_utilisateur;
