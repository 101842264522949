import React, { useState, useRef, useEffect } from 'react';
import './ShowTopContribution.css'; // Import du fichier CSS

import visage_m2 from '../assets/visage_m2.svg';
import visage_m1 from '../assets/visage_m1.svg';
import visage_n from '../assets/visage_n.svg';
import visage_p1 from '../assets/visage_p1.svg';
import visage_p2 from '../assets/visage_p2.svg';

import medaille_or from '../assets/medaille_or.svg';
import medaille_argent from '../assets/medaille_argent.svg';
import medaille_bronze from '../assets/medaille_bronze.svg';

const ShowTopContribution = ({ contributions, redactionLongue }) => {
  const [openedIndex, setOpenedIndex] = useState(null);

  const handleToggleClick = (index) => {
    if (index === openedIndex) {
      setOpenedIndex(null); // Ferme l'élément s'il est déjà ouvert
    } else {
      setOpenedIndex(index); // Ouvre l'élément sélectionné
    }
  };

  return (
    <>
    <div className="contributions-container">
      {contributions.map((contrib, index) => (
        <ContributionDetail
          key={index}
          contrib={contrib}
          index={index}
          redactionLongue={redactionLongue}
          opened={openedIndex === index}
          handleToggleClick={() => handleToggleClick(index)}
        />
      ))}
    </div>
    {redactionLongue &&
    <div style={{marginTop:'20px', fontStyle:'italic', marginTop:'0px'}}>Cliquez sur les contributions incomplètes pour les voir entièrement</div>
}
    </>
  );
};

const ContributionDetail = ({ contrib, index, redactionLongue, opened, handleToggleClick }) => {
  const contentRef = useRef(null);

  const getMedalImage = (index) => {
    switch (index) {
      case 0:
        return medaille_or;
      case 1:
        return medaille_argent;
      case 2:
        return medaille_bronze;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!redactionLongue && opened) {
      handleToggleClick(); // Ferme l'élément si la rédaction longue est désactivée
    }
  }, [redactionLongue, opened, handleToggleClick]);

  return (
    <div className={`contribution-detail ${redactionLongue ? "makeclickable" : "notclickable"}`} onClick={redactionLongue ? handleToggleClick : undefined}>
      <div className="contribution-index">
        {index < 3 ? (
          <img src={getMedalImage(index)} alt={`medaille ${index + 1}`} className="medaille-icon" />
        ) : (
          <span className="index-number">{index + 1}</span>
        )}
      </div>
      <div className="contribution-content">
        <div
          className={`contribution-text ${redactionLongue && !opened ? 'truncated' : ''}`}
          ref={contentRef}
        >
          {contrib.contribution}
        </div>
        <div className="contribution-notes">
        <div className="note-item">
            <img src={visage_m2} alt="-2" className="note-icon" />
            <span>{contrib.notes.moinsdeux}</span>
          </div>
          <div className="note-item">
            <img src={visage_m1} alt="-1" className="note-icon" />
            <span>{contrib.notes.moinsun}</span>
          </div>
          <div className="note-item">
            <img src={visage_n} alt="0" className="note-icon" />
            <span>{contrib.notes.zero}</span>
          </div>
          <div className="note-item">
            <img src={visage_p1} alt="+1" className="note-icon" />
            <span>{contrib.notes.un}</span>
          </div>
          <div className="note-item">
            <img src={visage_p2} alt="+2" className="note-icon" />
            <span>{contrib.notes.deux}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowTopContribution;
